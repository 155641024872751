.mat-form-field-appearance-outline .mat-mdc-text-field-wrapper {
  background-color: rgba(0, 0, 0, 0.04) !important;
  border-radius: 4px;
}

.mdc-line-ripple {
  display: none !important;
}

.phone-number-form-field {
  .country-selector {
    left: -15px !important;
    opacity: 1 !important;
  }

  .mdc-floating-label {
    left: 80px !important;

    &.mdc-floating-label--float-above {
      display: none;
    }
  }

  input {
    padding-left: 80px !important;
    position: relative;
    top: -7px;
  }
}
