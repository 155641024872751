//
.dicom-viewer-modal {
  .mat-mdc-dialog-container {
    padding: 0 !important;
    background-color: #1f2531 !important;
    overflow: hidden;
  }
}

.dicom-viewer__toolbar__dropdown {
  background-color: #1f2531 !important;
  padding: 5px;
}
