
.study-nav__general-menu {
  width: 250px;
  font-size: 12px;
  max-width: 240px !important;
  min-width: 200px !important;
  -webkit-box-shadow: 0 0 6px 0 rgba(0, 0, 0, 0.1) !important;
  box-shadow: 0 0 6px 0 rgba(0, 0, 0, 0.1) !important;
}


// study designer
.study-main {
  &__stage {

    .export-menu-trigger {
      width: 7.375rem;
      max-width: 100%;
      padding: 0 .5rem;

      i {
        margin-left: 1.25rem;
      }
    }

    &__export-menu {
      width: 14.375rem;
      max-height: 22.188rem !important;
      padding: 1rem .5rem .5rem .5rem !important;
      border: none !important;
      box-shadow: -4px 4px 10px rgba(0, 0, 0, 0.12) !important;
      border-radius: 5px !important;

      &__item {
        margin-bottom: 1.6rem;

        &:last-child {
          margin: 0;
        }

        &__title {
          font-weight: 700;
          border-bottom: 1px solid #F1F2F3;
          padding: 0 .5rem 1rem .5rem;
        }

        .mat-mdc-menu-item {
          height: 2.5rem;
          line-height: 40px;
          margin-top: .5rem;
          color: #6C747C;

          .mdc-list-item__primary-text {
            flex: 1;
            display: flex;
            justify-content: space-between;
          }


          span {
            display: flex;
            align-items: center;
            font-weight: 400;

            svg {
              margin-right: .5rem;
            }
          }

          &.checked {
            span {
              color: $primary;
              font-weight: 700;
            }

            svg {
              path {
                fill: $primary;
              }
            }
          }
        }
      }

      .mat-mdc-radio-button {
        margin-right: 0;
      }
    }
  }
}

// template import
.study-template-import,
.cdk-drag-preview {
  .drop-list {
    .mat-mdc-checkbox {
      .mdc-checkbox__background {
        border-color: #fff !important;
        background-color: unset !important;
      }
    }

    .drop-list__item--parameter {
      .mat-mdc-checkbox {
        .mdc-checkbox__background {
          border-color: #ccc !important;
          background-color: unset !important;

          .mdc-checkbox__background .mdc-checkbox__checkmark {
            stroke: #ccc !important;
          }
        }
      }
    }
  }
}

.drop-list,
.drop-list__item {
  .mat-mdc-checkbox {
    .mdc-checkbox__background {
      border-color: #fff !important;
      background-color: unset !important;
    }
  }

  .drop-list__item--parameter {
    .mat-mdc-checkbox {
      .mdc-checkbox__background {
        border-color: #ccc !important;

        .mdc-checkbox__background .mdc-checkbox__checkmark {
          stroke: #ccc !important;
        }
      }
    }
  }
}

//@at-root/* Animate items as they're being sorted. */

.drop-list {
  &__item {
    &__name {
      &__select-item {
        .mat-mdc-checkbox {
          .mdc-checkbox__background {
            .mdc-checkbox__background .mdc-checkbox__checkmark {
              stroke: #fff !important;
            }
          }
        }
      }
    }
  }
}

.cdk-drag-preview {
  box-sizing: border-box;
  border-radius: 4px;
  box-shadow: unset;

  &.drop-list__item {
    min-height: 40px;
    border-radius: 4px;
    border: 2px solid $primary;
    font-weight: bold;
    margin-bottom: 1rem;
    padding: 0;
    display: flex;
    align-items: center;
    justify-content: space-between;
    box-sizing: border-box;
    cursor: move;
    background: #f4f7fa;
    position: relative;

    .drop-list__item__name {
      display: flex;
      align-items: center;

      .drop-list__item__name__select-item {
        border-top-left-radius: 4px !important;
        border-bottom-left-radius: 4px !important;
        width: 2.75rem;
        height: 2.75rem;
        display: flex;
        align-items: center;
        justify-content: center;
        margin-right: 0.5rem;

        .mat-mdc-checkbox {
          .mdc-checkbox__background {
            .mdc-checkbox__background .mdc-checkbox__checkmark {
              stroke: #fff !important;
            }
          }
        }
      }

      .drop-list__item__name__remove-item {
        margin-right: 0.5rem;

        button {
          border-top-left-radius: 4px !important;
          border-bottom-left-radius: 4px !important;
          border-top-right-radius: 0 !important;
          border-bottom-right-radius: 0 !important;
          color: $white;
          padding: 0;
          width: 2.75rem;

          i {
            font-size: 1.5rem;
          }
        }
      }

      > i {
        color: $primary;
        font-size: 0.8rem;
        margin-left: 0.5rem;
        cursor: pointer;
      }
    }

    .drop-list__item__icons {
      display: flex;
      align-items: center;

      .fa-arrows-alt {
        color: $primary;
        font-size: 1.5rem;
      }

      .fa-chevron-down,
      .fa-chevron-up {
        color: $primary;
      }
    }

    &.drop-list__item--group {
      .drop-list__item__name__select-item,
      .drop-list__item__name__remove-item {
        background-color: $legend-group-color;
      }
    }

    &.drop-list__item--subgroup {
      .drop-list__item__name__select-item,
      .drop-list__item__name__remove-item {
        background-color: $legend-subgroup-color;
      }
    }

    &.drop-list__item--parameter {
      .drop-list__item__name__select-item,
      .drop-list__item__name__remove-item {
        background-color: $legend-parameter-color;
      }

      .mat-mdc-checkbox {
        .mdc-checkbox__background {
          border-color: #ccc !important;

          .mdc-checkbox__background .mdc-checkbox__checkmark {
            stroke: #ccc !important;
          }
        }
      }
    }
  }
}

.cdk-drag-placeholder {
  opacity: 1;
  border: 2px dashed $primary !important;
  background: unset !important;

  > div {
    display: none !important;
  }
}

.cdk-drag-animating {
  transition: transform 250ms cubic-bezier(0, 0, 0.2, 1);
}

.drop-list.cdk-drop-list-dragging .drop-list__item:not(.cdk-drag-placeholder) {
  transition: transform 250ms cubic-bezier(0, 0, 0.2, 1);
}
