@import 'vars';

.navigation {
  height: 72px;
  padding: 0 20px;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  box-shadow: none;
  z-index: 100;

  &__user-profile {
    &__dropdown {
      .mat-mdc-menu-item {
        span {
          font-size: 11px;
          position: relative;
          top: -4px;
          left: 6px;
        }
      }
    }
  }
}
