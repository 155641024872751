@import "src/sass/colors";

.cal-month-view {
  .cal-day-cell {
    .cal-day-number {
      width: 25px;
      height: 25px;
      color: $secondaryTextColor;
      margin: 15px 0 10px 15px;
      float: left;
      opacity: 1;
      font-size: 14px;
      display: flex;
      align-items: center;
      justify-content: center;
    }
    &.cal-today {
      background: none;
      .cal-day-number {
        color: $white;
        background-color: $primary;
        border-radius: 100%;
        font-size: 14px;
      }
    }
  }
}
