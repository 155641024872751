@import "colors";

.mat-mdc-button {
  padding-left: 1rem;
  padding-right: 1rem;

  &.close {
    i {
      font-size: 1.875rem;
    }
  }

  &.button-gray {
    background-color: #f1f2f3;
  }

  &.button-green {
    color: $submit-input-button-text-color !important;
    background-color: $submit-input-button-background-color !important;
  }

  &.button-danger:not(.mat-mdc-outlined-button) {
    color: $white !important;
    background-color: $red;
  }

  &.button-primary-transparent {
    border: 2px solid $primary;
    background: none;
    color: $primary !important;
    -webkit-transition: all 500ms;
    -moz-transition: all 500ms;
    -o-transition: all 500ms;
    transition: all 500ms;

    &:hover {
      color: $white !important;
      border: 2px solid transparent;
      background-color: $primary;
    }
  }

  &.button-gray-transparent {
    border: 2px solid #f1f2f3;
    background: none;
    color: $primary !important;
    -webkit-transition: all 500ms;
    -moz-transition: all 500ms;
    -o-transition: all 500ms;
    transition: all 500ms;

    &:hover {
      border: 2px solid transparent;
      background-color: rgba(95, 138, 180, 0.15);
    }
  }

  &.button-light-transparent {
    border: 2px solid #f1f2f3;
    background: #fff;
    color: #141516 !important;
    font-size: 12px;
    -webkit-box-shadow: 0 0 1px 0.5px rgba(217,217,219,1);
    -moz-box-shadow: 0 0 1px 0.5px rgba(217,217,219,1);
    box-shadow: 0 0 1px 0.5px rgba(217,217,219,1);
    padding: 8px !important;
  }

  &.button-danger-transparent {
    border: 2px solid $button-error-border-color;
    background: $button-error-background-color;
    color: $button-error-text-color !important;
    -webkit-transition: all 500ms;
    -moz-transition: all 500ms;
    -o-transition: all 500ms;
    transition: all 500ms;

    &:hover {
      color: $white !important;
      border: 2px solid transparent;
      background-color: $button-error-hover-background-color;
    }
  }
}

.mat-mdc-button.mat-mdc-button-base,
.mat-mdc-raised-button.mat-mdc-button-base,
.mat-mdc-unelevated-button.mat-mdc-button-base,
.mat-mdc-outlined-button.mat-mdc-button-base {
  height: 2.75rem;

  &:hover,
  &:active {
    box-shadow: none !important;
  }
}

body:not(.at) button,
.mat-mdc-raised-button,
.mat-mdc-button {
  height: 2.75rem;
  font-weight: 700;
  letter-spacing: 0;
  outline: none;
  box-shadow: none;
  border-radius: 6px;
  border: none;

  &.has-spinner {
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .mat-mdc-progress-spinner:not(.mat-primary) {
    circle {
      stroke: #fff;
    }
  }

  &.only-icon {
    width: 2.75rem;
    height: 2.75rem;
    padding: 0;
    border-radius: 4px;
  }

  &.only-icon-sm {
    width: 2rem;
    height: 2rem;
    padding: 0;
    border-radius: 4px;
  }

  &:disabled {
    cursor: not-allowed;
    color: #222 !important;
    opacity: .4;
  }

  &:disabled {
    &:not(.button-primary-transparent) {
      cursor: not-allowed;
      color: #222 !important;
      opacity: .4;
    }

    &.button-primary-transparent {
      color: rgba($primary, .5) !important;
      border-color: rgba($primary, .5) !important;
      background: transparent !important;
    }

    &.mat-primary {
      color: $white !important;
      background-color: rgba($primary, .2) !important;
      opacity: initial;
    }
  }
}
