// colors var name https://colornamer.robertcooper.me/


// Main colors
$white: #FCFCFC;
$heartOfIce: #F4FAFF;
$veilingWaterfalls: #D5EAFF;
$iceColdStare: #B0D4F7;
$stretchOfWater: #9EB9D2;
$electricEel: #8DBEEE;
$overcast: #75A4D1;
$lichenBlue: #5F8AB4;
$hammerheadShark: #4B7197;
$bluey: #395A7A;
$blueAccolade: #28435D;
$tangaroa: #192D3F;
$Rhino: #28435D;
$Tundora: #4B4B4B;

$zappyZebra: #F1F2F3;
$leadbelcher: #CACACA;
$rainyGrey: #A5A5A5;
$stargazing: #424447;

$pumpingSpice: #F45050;
$roseMadder: #E63A3A;
$riseNShine: #FFC42F;
$dentCorn: #EFB31A;
$celestialGreen: #33D9B2;
$jadeMountain: #2DCDA7;
$Salmon: #FF7979;
$Hibiscus: #ac2360;
$Saffron: #F9C02C;
$Alto: #D9D9D9;
$MaroonFlush: #AC2260;

// button colors
$button-error-text-color: #F45050;
$button-error-border-color: #F45050;
$button-error-background-color: #FCFCFC;
$button-error-hover-background-color: #F45050;

// auth - login
$phi-agreement-background-color: rgba(255, 255, 255, .4);
$phi-agreement-border-top-color: $Saffron;
$phi-agreement-circle-icon-color: $Saffron;
$phi-agreement-circle-border-color: $Saffron;
$phi-agreement-title-color: #565C58;
$phi-agreement-text-color: #565C58;
$phi-agreement-button-close-color: #BFD0E1;

// Main navigation sidenav
$sidenav-item-background-active: $bluey;

// audit
$audit-toggle-button-background: $Hibiscus;
$audit-requests-counter-background: $Hibiscus;
$audit-request-border-color: $Hibiscus;

$audit-request-active-background-color: #f8f9fa;
$audit-request-active-border-color: $lichenBlue;

// audit comments
$audit-comment-username-notification-color: $lichenBlue;
$audit-comment-notification-update: $lichenBlue;
$audit-comment-notification-approve: $celestialGreen;
$audit-comment-notification-reject: $Salmon;
$audit-comment-notification-withdraw: $Tundora;

// modal
$modal-title-color: #222222;
$modal-subtitle-color: #141516;

// input data
$submit-input-button-text-color: $white;
$submit-input-button-background-color: $celestialGreen;
$draft-value-color: #4F4F4F;

// file list
$file-uploaded-background-color: $lichenBlue;
$file-uploading-background-color: $tangaroa;
$file-accepted-background-color: $celestialGreen;
$file-archived-background-color: $Saffron;
$file-in-queue-background-color: $rainyGrey;
$file-audit-background-color: $Hibiscus;

// study-settings -  roles & rights tab
$users-count-background-color: $Alto;
$user-not-approved: $MaroonFlush;
$user-not-have-role: $riseNShine;
$user-selected: $lichenBlue;
$user-status-tooltip-border-color: $Rhino;
