body:not(.study-settings-body) {
  h2 {
    font-weight: 700;
    font-size: 1.5rem;
  }

  h3 {
    font-weight: 700;
    font-size: 1.25rem;
    letter-spacing: 1px;
  }

  h4 {
    font-size: 1.75rem;
    font-weight: 700;
    letter-spacing: 1px;
  }

  // titles
  .xs-title,
  .sm-title,
  .md-title,
  .xl-title,
  .xxl-title {
    font-weight: 700;
    letter-spacing: 1px;
  }

  .xs-title {
    font-size: 0.75rem !important;
  }

  .sm-title {
    font-size: 1rem;
  }

  .md-title {
    font-size: 1.25rem;
  }

  .xl-title {
    font-size: 1.5rem;
  }

  .xxl-title {
    font-size: 1.75rem;
  }
}


body.study-settings-body {
  font-family: 'Roboto', Arial, sans-serif !important;
  font-size: 16px !important;

  h1 {
    font-size: var(--heading-h1-size);
  }

  h2 {
    font-size: var(--heading-h2-size);
  }

  h3 {
    font-size: var(--heading-h3-size);
  }

  h4 {
    font-size: var(--heading-h4-size);
  }

  h5 {

    font-size: var(--heading-h4-size);
  }

  h1, h2, h3, h4, h5, h6 {
    font-weight: 700;
    letter-spacing: 1.6px;
    line-height: 120%;
  }

  p {
    line-height: 150%;
    letter-spacing: 0.6px;
  }

  @for $i from 1 through 4 {
    .body-#{$i} {
      line-height: 150%;
      letter-spacing: 0.6px;
    }
  }
}

.body-1 {
  font-size: var(--paragraph-small-font-size); // 10px
}

.body-2 {
  font-size: var(--paragraph-regular-font-size); // 12px
}

.body-3 {
  font-size: var(--paragraph-large-font-size) // 14px
}

.body-4 {
  font-size: var(--paragraph-extra-large-font-size); // 16px
}
