
.stroma-tabs {
  display: flex;
  flex: 1;
  overflow: auto;
  border-bottom: 1px solid #EBEBEC;

  .stroma-tab-item {
    height: 100%;
    color: #656669;
    font-weight: 500;
    border-radius: 6px 6px 0 0;
    border-bottom-style: solid;
    border-bottom-width: 1px;
    border-bottom-color: transparent;
    background: none;
    padding: 0 12px;
    cursor: pointer;
    white-space: nowrap;

    &:not(:disabled) {
      &:hover {
        color: #141516;
        background-color: #F4F5F5;
      }

      &:active {
        color: #141516;
        background-color: #EBEBEC;
        -webkit-box-shadow: inset 0 1px 1px 0.5px rgba(167, 168, 171, 1);
        -moz-box-shadow: inset 0 1px 1px 0.5px rgba(167, 168, 171, 1);
        box-shadow: inset 0 1px 1px 0.5px rgba(167, 168, 171, 1);
      }

      &.active {
        color: #141516;
        border-color: #212224;
      }
    }

    &:disabled {
      color: #04060F59;
      cursor: not-allowed;
    }
  }


  &-small {
    .stroma-tab-item {
      height: 32px;
      font-size: 12px;
    }
  }

  &-regular {
    .stroma-tab-item {
      height: 40px;
      font-size: 14px;
    }
  }

  &-medium {
    .stroma-tab-item {
      height: 40px;
      font-size: 16px;
    }
  }

  &-large {
    .stroma-tab-item {
      height: 48px;
      font-size: 20px;
    }
  }
}
