@import 'vars';

.mat-mdc-table,
table {
  width: 100%;
  box-shadow: none !important;
}

.mat-mdc-table {
  border: 1px solid #e0e0e0 !important;

  .mat-mdc-header-row {
    .mat-mdc-header-cell {
      color: #000;
      font-weight: 700;
      height: 49px !important;
      background: #f8f8f8;

      &.mat-column-actions {
        text-align: right;
      }

      &.right {
        .mat-sort-header-container {
          position: relative;
          left: 20px;
          justify-content: flex-end;
        }
      }
    }
  }

  .mat-mdc-row {
    .mat-mdc-cell {
      color: #222;
      height: 49px !important;

      &.mat-column-actions {
        > div {
          display: flex;
          justify-content: flex-end;
          align-items: center;
        }
      }

      .td-label {
        display: none;
      }

      button {
        height: 36px;
      }
    }

    &:hover {
      background-color: #e0e0e0;
    }
  }


  &.responsive {
    @media (max-width: $screen-extra-large - 1) {
      border: none;
      white-space: normal !important;

      .mat-mdc-header-row {
        display: none;
      }

      .mat-mdc-row {
        min-height: auto;
        height: auto;
        width: 100%;
        border: 1px solid #e0e0e0;
        padding: 20px;
        margin-bottom: 20px;
        align-items: unset;
        display: flex;
        flex-direction: column;
        border-radius: 4px;

        .mat-mdc-cell {
          height: auto !important;
          border: unset;
          margin-bottom: 10px;
          text-align: left !important;

          &:first-of-type,
          &:last-of-type {
            padding: 0 !important;
          }

          .td-label {
            font-weight: bold;
            display: inline-block;
            margin-right: 5px;
          }
        }

        &:hover {
          background-color: transparent;
        }
      }
    }
  }

  &.v2 {
    border-collapse: separate;
    border-spacing: 0;

    .mat-mdc-header-row {
      .mat-mdc-header-cell {
        color: #656669;
        font-size: 14px;
        font-weight: 500;
        border-top: 1px solid #D9D9DB;
        background: unset;
        height: 56px !important;
        padding-top: .25rem;
        padding-bottom: .25rem;

        &:first-child {
          border-left: 1px solid #D9D9DB;
          border-top-left-radius: 8px;
        }

        &:last-child {
          border-right: 1px solid #D9D9DB;
          border-top-right-radius: 8px;
        }

        .sort {
          opacity: 0;
          width: 1rem;
        }

        &.active {
          background-color: #EBEBEC;
        }

        &:hover {
          transition: all 100ms;
          background-color: #EBEBEC;
        }

        &:hover,
        &.active {
          .sort {
            opacity: 1;
          }
        }

        &.sorted {
          .sort {
            opacity: 1;
          }
        }
      }
    }

    .mdc-data-table__content {
      .mat-mdc-row {
        height: 56px;

        .mat-mdc-cell {
          &:first-child {
            border-left: 1px solid #D9D9DB;
          }

          &:last-child {
            border-right: 1px solid #D9D9DB;
          }
        }

        &:last-child {
          .mat-mdc-cell {
            border-bottom: 1px solid #D9D9DB;

            &:first-child {
              border-bottom-left-radius: 8px;
            }

            &:last-child {
              border-bottom-right-radius: 8px;
            }
          }
        }
      }
    }
  }
}
