.mat-badge-content {
  color: #fff !important;
  background: #5f8ab4  !important;;
}

// meta tags
.meta-menu {
  font-size: 12px;
  max-width: 260px !important;
  min-width: 200px !important;
  -webkit-box-shadow: 0 0 6px 0 rgba(0, 0, 0, 0.1) !important;
  box-shadow: 0 0 6px 0 rgba(0, 0, 0, 0.1) !important;

  .meta-menu-options {
    border-bottom: 1px solid #e0e0e0;
  }

  .mat-mdc-menu-item {
    height: 32px;
    min-height: 32px;
    padding-left: 8px;
    padding-right: 8px;

    .mat-mdc-menu-item-text {
      width: 100%;
    }

    .mdc-switch {
      width: 28px;
      height: 16px;

      .mdc-switch__track {
        width: 28px;
        height: 16px;
        background-color: #EBEBEC;
      }

      .mdc-switch__handle-track {
        width: 12px;
        height: 12px;
        top: 2px;
        left: 2px;
      }

      .mdc-switch__handle {
        width: 12px;
        height: 12px;

        &::before {
          background-color: #fff;
        }
      }

      .mdc-switch__icons {
        display: none;
      }

      .mdc-elevation-overlay {
        background-color: #fff;
      }
    }
  }
}


