:not(.meta-menu) {
  .mdc-switch {
    width: 28px;
    height: 16px;

    .mdc-switch__track {
      width: 28px;
      height: 16px;
      background-color: #EBEBEC;
    }

    .mdc-switch__handle-track {
      width: 12px !important;
      height: 12px !important;
      top: 2px !important;
      left: 2px !important;
    }

    .mdc-switch__handle {
      width: 12px !important;
      height: 12px !important;

      &::before {
        background-color: #fff;
      }
    }

    .mdc-switch__icons {
      display: none;
    }

    .mdc-elevation-overlay {
      background-color: #fff;
    }
  }
}
