.mat-mdc-standard-chip{
  --mdc-chip-label-text-color: var(--main-base-color);
  --mdc-chip-with-trailing-icon-trailing-icon-color: var(--main-base-color);
  --mat-icon-color: var(--main-base-color);
  --mdc-chip-elevated-container-color: #FCFCFC
}

.mat-mdc-chip-grid .mat-mdc-standard-chip {
  border-radius: .5rem !important;
   .mat-chip-trailing-icon {
     line-height: 150%;
   }
}
.mdc-evolution-chip-set .mdc-evolution-chip {
  margin: 0 4px !important;
}
