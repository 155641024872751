.mdc-button {
  --mdc-typography-button-letter-spacing: normal;
}

.mat-mdc-raised-button:not(:disabled),
.mat-mdc-unelevated-button:not(:disabled) {
  color: var(--background-color-white);
  svg {
    path {
      fill: var(--background-color-white);
    }
  }

  &.mat-primary {
    color: white;
    background-color: var(--main-base-color);
  }

  &.mat-warn {
    background-color: var(--backgorund-color-red);
  }

  &.button-success {
    background-color: var(--background-color-light-green);
  }
}

.mat-mdc-outlined-button:not(:disabled) {
  &.mat-primary {
    color: var(--main-base-color);
    border: 2px solid var(--main-base-color);

    &:hover {
      background-color: var(--main-base-color);
    }
  }

  &.mat-warn {
    color: var(--backgorund-color-red);
    border: 2px solid var(--backgorund-color-red);

    &:hover {
      background-color: var(--backgorund-color-red);
    }
  }

  &.button-success {
    color: var(--background-color-light-green);
    border: 2px solid var(--background-color-light-green);

    &:hover {
      background-color: var(--background-color-light-green);
    }
  }

  &:hover {
    color: var(--background-color-white);
    border-color: transparent;

    svg {
      path {
        fill: var(--background-color-white);
      }
    }
  }
}

.mat-mdc-icon-button.mat-mdc-button-base {
  padding: 0;
  font-size: initial;
  font-weight: normal;

  .mdc-icon-button__ripple {
    border-radius: unset;
  }

  &.mat-icon-sm-button {
    width: 1rem;
    height: 1rem !important;
  }

  &.mat-icon-md-button {
    width: 1.5rem;
    height: 1.5rem !important;
  }

  &.mat-icon-lg-button {
    width: 2rem;
    height: 2rem !important;
  }
}
