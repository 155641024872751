.error {
  z-index: 99;
}

.alert {
  position: fixed;
  left: 50%;
  top: 2.5rem;
  transform: translate(-50%, 0);
  z-index: 99999;
  min-width: 150px;
  padding: calc(1rem - 2px) 1rem;
  border: 2px solid transparent;
  border-radius: .625rem;
  font-size: var(--paragraph-extra-large-font-size);
  font-weight: 700;
  --webkit-filter: drop-shadow(0px 4px 20px rgba(51, 217, 178, 0.10));
  filter: drop-shadow(0px 4px 20px rgba(51, 217, 178, 0.10));
  backdrop-filter: blur(20px);
  text-align: center;

  &-success {
    background-color: var(--status-color-success-light);
    border-color: var(--status-color-success);
  }

  &-warning {
    background-color: var(--status-color-warning-light);
    border-color: var(--status-color-warning);
  }

  &-danger {
    background-color: var(--status-color-error-light);
    border-color: var(--status-color-error);
  }

  p {
    padding: 0;
    margin: 0;
  }

  i {
    padding-right: 5px;
    vertical-align: middle;
    font-size: 24px;
  }

  .close-alert {
    -webkit-appearance: none;
    position: relative;
    float: right;
    padding: 0;
    border: 0;
    cursor: pointer;
    color: inherit;
    background: 0 0;
    font-size: 21px;
    line-height: 1;
    font-weight: bold;
    text-shadow: 0 1px 0 rgba(255, 255, 255, 0.7);
    filter: alpha(opacity=40);
    opacity: 0.4;
    &:hover {
      filter: alpha(opacity=70);
      opacity: 0.7;
    }
  }
}
