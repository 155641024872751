:root {
  // headings
  --heading-h1-size: 3rem; //48px
  --heading-h2-size: 2.5rem; // 40px
  --heading-h3-size: 2rem;// 32px
  --heading-h4-size: 1.5rem; // 24px
  --heading-h5-size: 1.25rem; // 20px
  --heading-h6-size: 1.125rem; // 18px

  // text - paragraphs
  --paragraph-small-font-size: .625rem; // 10px
  --paragraph-regular-font-size: .75rem; // 12px
  --paragraph-large-font-size: .875rem; // 14px
  --paragraph-extra-large-font-size: 1rem; // 16px

  // text colors
  --main-dark-color: #28435D;
  --main-base-color: #5F8AB4;
  --main-light-color: #75A4D1;
  --neutral-dark-color: #414447;
  --neutral-base-color: #868686;
  --neutral-light-color: #CACACA;

  // status colors
  --status-color-error: #F45050;
  --status-color-error-light: #f9a6ad;
  --status-color-warning: #FB8D00;
  --status-color-warning-light: #f9c17a;
  --status-color-success: #33D9B2;
  --status-color-success-light: #EBFBF7;

  // background colors

  --backgorund-color-red: #F45050;
  --backgorund-color-light-blue: #F4FAFF;
  --background-color-light-green: #33D9B2;
  --background-color-gray: #F1F2F3;
  --background-color-light-gray: #FAFAFA;
  --background-color-white: #FCFCFC;

  // study state color

  --study-state-inPreparation-background-color: #EBEBEC;
  --study-state-inPreparation-text-color: #48494C;

  --study-state-active-background-color: #B3FF96;
  --study-state-active-text-color: #0A5500;

  --study-state-paused-background-color: #FFEC6D;
  --study-state-paused-text-color: #5D4500;

  --study-state-aborted-background-color: #B3FF96;
  --study-state-aborted-text-color: #970000;

  --study-state-completed-background-color: #83FFF7;
  --study-state-completed-text-color: #00524D;

  // bootstrap
  --bs-body-font-size: 14px;
}

.row {
  --bs-gutter-x: 1rem;
}

$buttonHeight: 44px;

$max-mobile-size: 767px;
$max-tablet-size: 1199px;

$screen-extra-large: 1200px;
$screen-large: 992px;
$screen-medium: 768px;
$screen-small: 576px;
