@import 'colors';

body {
  margin: 0;
  font-family: 'Roboto', Arial, Helvetica, sans-serif !important;
  background-color: var(--background-color-white);
}

#app-body.has-backdrop {
  overflow: hidden;
}

.mat-drawer-backdrop.mat-drawer-shown {
  position: fixed;
}

.header {
  justify-content: space-between;
}

.mat-drawer-content {
  background-color: var(--background-color-white);
  overflow: hidden !important;
}

.mat-nav-list .mat-mdc-list-item {
  font-size: 15px;
}

.nav-tool-items {
  display: inline-block;
  margin-right: 13px;
}

.mat-mdc-list-item.active {
  background: rgba(0, 0, 0, 0.04);
}

.mat-sidenav {
  background-color: #f2f2f2;
  font-size: 14px;
  width: 305px;
  border-right: solid 1px rgba(0, 0, 0, 0.12);
  padding: 15px;
  display: inherit;
  box-shadow: none !important;
}


.mat-drawer-inner-container {
  overflow-x: hidden !important;
}

.mat-drawer-backdrop {
  height: 100vh;
}

.header {
  position: sticky;
  position: -webkit-sticky;
  top: 0;
  z-index: 1000;
}

mat-sidenav mat-icon {
  margin-right: 12px;
}

.hamburger {
  margin-top: 5px;
  cursor: pointer;
}

.mat-mdc-radio-button,
.mat-mdc-radio-group {
  margin-right: 25px;
}

.controlers-wrapper > * {
  width: 100%;
  padding: 0;
}

.misc-bottom-padding {
  margin: 8px 0 10px;
}

.misc-bottom-padding mat-label {
  margin-right: 15px;
}

mat-radio-group mat-radio-button {
  margin-left: 5px;
}

// body .mat-list-item {
//   margin-bottom: 10px;
// }

.mat-mdc-list-base .mat-mdc-list-item {
  font-size: 13px !important;
}

.inner-wrapper {
  padding: 15px 0 130px;
  width: 100%;
}

.inner-wrapper mat-card {
  display: inline-block;
  margin: 0 6% 0 0;
  vertical-align: top;
  width: 44%;
}

.full-wrapper {
  width: 100%;
}

.multiple-items {
  position: relative;
}

.multiple-items .tooltip-info {
  right: 0;
  top: 7px;
  cursor: pointer;
  color: #a1a7c7;
  position: absolute;
  font-size: 20px;
}

body .push-right {
  margin-right: 10px;
}

.no-data {
  text-align: center;
  padding-top: 30px;
  color: #6c75a9;
}

.example-card {
  max-width: 400px;
}

.title-group {
  margin-bottom: 25px;
}

.card-deck-container {
  width: 100%;
  max-width: 1200px;
  position: relative;
  border-radius: 2px;
  padding: 10px 10px 30px;
  margin: 10px 10px 10px 10px;
  background-color: #f5f5f5;
}

.card-item {
  padding: 3px 3px 3px 3px;
}

mat-grid-tile {
  background: lightblue;
}

@media (max-width: 1024px) {
  .inner-wrapper mat-card {
    width: 100%;
  }

  .misc-bottom-padding mat-label {
    display: block;
    padding-bottom: 10px;
  }

  .mat-nav-list .mat-mdc-list-item {
    font-size: 14px;
  }
}

@media (max-width: 767px) {
  .nav-tool-items {
    margin-right: 0;
  }

  .hamburger {
    visibility: visible !important;
  }

  .container {
    max-width: 100%;
  }
}

.inline-logo {
  color: #fff;
  font-style: italic;
  vertical-align: middle;
  margin-top: 10px;
}

.home-logo {
  a {
    text-decoration: none;
  }
}

.lock-btn,
.clickable {
  cursor: pointer;
}

.quick-access-card {
  cursor: pointer;
}

// navigation templates
.navigation__templates__dropdown {
  width: 158px;
}

// navigation notifications
.navigation__notifications__dropdown {
  width: 280px;
  max-height: 380px !important;
  margin-top: 14px;
  margin-left: -70px;

  &::before {
    content: '';
    position: absolute;
    top: 0;
    left: 12px;
    width: 0;
    height: 0;
    border-style: solid;
    border-width: 0 10px 10px 10px;
    border-color: transparent transparent var(--background-color-white) transparent;
  }

  .mat-mdc-menu-content:not(:empty) {
    padding: 0;
  }
}

// navigation user dropdown
.navigation__user-profile__dropdown {
  width: 175px;
}

// data & cockpit case - modal
.cockpit-input-dialog {
  width: 100%;
}

// mat card
.mat-mdc-card-header-text {
  margin: 0 !important;
}

.profile-image-wrapper {
  width: 24px;
  height: 24px;
  border-radius: 100%;
  display: inline-block;
  background-color: $primary;
  background-size: cover;
  position: relative;

  i {
    color: rgba($white, 0.7);
    font-size: 20px;
    position: absolute;
    bottom: -2px;
    left: 5px;
  }
}

.user-avatar {
  width: 24px;
  height: 24px;
  border-radius: 100%;
}

// case configuration modal
body > .cdk-drag {
  opacity: 0;
}

body > .cdk-drag-preview {
  opacity: 1;
}

@media (max-width: 576px) {
  //
}

@media (max-width: 1024px) {
  .aritids-table-wrapper {
    max-width: 100%;
    overflow: auto;
  }
  .mat-mdc-table {
    width: 1200px;
  }
}

// remove arrows on input number
input[type='number'] {
  -moz-appearance: textfield;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

html.cdk-global-scrollblock {
  position: static !important;
  width: initial !important;
  overflow: hidden !important;
}

html.cdk-global-scrollblock body {
  width: 100%;
}

.xng-breadcrumb-trail {
  margin: 0;
}

.xng-breadcrumb-link {
  cursor: pointer;
}


//

.float-end {
  float: right;
}

.country-search {
  position: sticky;
  top: 0;
  left: 0;
  background-color: $white;
  z-index: 1;
  border: none;
  border-bottom: 1px solid #e3e3e3;
  border-radius: 0;
  outline: none;
}

// image and video player modal
.image-view-modal,
.video-player-modal {
  .mat-mdc-dialog-container,
  .mat-mdc-dialog-container .mdc-dialog__surface {
    overflow: hidden;
  }
}

.cdk-overlay-pane.mat-mdc-dialog-panel.image-view-modal{
  max-width: 100%;
}

// manage users studies * roles
.studies-menu {
  width: 400px;
  max-width: 400px !important;

  .search-studies {
    padding: 0 1rem;
  }

  .study-list {
    max-height: calc(400px - 74px);
    overflow: auto;
  }

  .mdc-list-item__primary-text {
    flex: 1;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }


  .study {
    display: flex;

    .mat-mdc-menu-item-text {
      flex: 1;
    }

    .study-name {
      flex: 0 0 45%;
      width: 45%;
    }

    .roles-list {
      flex: 0 0 45%;
      width: 45%;
    }
  }
}

// template import
.study-template-import {
  .mdc-checkbox__ripple {
    display: none;
  }
}
