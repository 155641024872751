.artidis-modal {
  &__header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    border-bottom: 1px solid rgba(0, 0, 0, 0.12);
    padding: 24px;

    &__title {
      font-size: 1.5rem;
      font-weight: 700;
    }

    &__subtitle {
      color: $modal-subtitle-color;
      font-size: 1rem;
    }

    > .artidis-modal-subtitle, p {
      margin: 0;
      color: $modal-subtitle-color;
    }
  }

  .mat-divider {
    margin: 0 -24px;
  }

  .mat-mdc-dialog-content {
    padding-top: 30px;
    padding-bottom: 30px;
    max-height: calc(100vh - 260px);

    > .mat-mdc-dialog-actions {
      margin-top: 2rem !important;
    }
  }

  .mat-mdc-dialog-actions {
    padding: 9px 24px;
    border-top: 1px solid rgba(0, 0, 0, 0.12);

    button {
      min-width: 8.81rem !important;
    }

    .button-close {
      @extend .button-gray;
      margin-right: .5rem;
      @media (max-width: $screen-large) {
        margin: 0;
      }
    }
  }
}

.artidis-modal-v2 {
  .header {
    .close-icon {

    }
  }
}

.mat-mdc-dialog-container {
  position: relative;
  .mdc-dialog__surface {
    overflow-x: hidden;
  }
}

.case-table-configuration-modal {
  .mat-mdc-dialog-container .mdc-dialog__surface {
    border-radius: 10px;
  }
}
