.mdc-tooltip {
  .mdc-tooltip__surface {
    color: var(--main-dark-color) !important;
    font-size: 12px;
    padding: .5rem;
    border: 2px solid var(--main-dark-color) !important;
    background: var(--background-color-white) !important;
    border-radius: 10px !important;
    text-align: center !important;
  }

  // TODO study-roles-management add arrow to the top of the tooltip later!
  &::before {
    content: '';
    border-bottom: 20px solid #0000;
    clip-path:
      polygon(0 0,100% 0, /* [1],[2] */
        100% calc(100% - 20px), /* [3] */
        calc(50% - 40px/2) calc(100% - 20px), /* [4] */
        var(50%) 100%, /* [5] */
        calc(50% + 40px/2) calc(100% - 20px), /* [6] */
        0 calc(100% - 20px) /* [7] */
      );
  }
}
