@import 'colors';
@import 'vars';

.width-50 {
  max-width: 50%;
  width: 50%;
}

.width-100 {
  max-width: 100%;
  width: 100%;
}

.height-100 {
  height: 100%;
}

.text-center {
  text-align: center;
}

.text-right {
  text-align: right !important;
}

.text-left {
  text-align: right !important;
}

.border-none {
  border: none !important;
}

.cursor-pointer {
  cursor: pointer;
}

.cursor-not-allowed {
  cursor: not-allowed !important;
}

.margin-0 {
  margin: 0;
}

.padding-0 {
  padding: 0;
}

.font-style-italic {
  font-style: italic;
}

.position-relative {
  position: relative !important;
}

.flex-1 {
  flex: 1;
}

.justify-content-center {
  justify-content: center;
}

.justify-content-space-between {
  justify-content: space-between;
}

.justify-content-flex-end {
  justify-content: flex-end;
}

.flex-direction-column {
  flex-direction: column;
}

.flex-direction-row-reverse {
  flex-direction: row-reverse;
}

.flex-wrap-wrap {
  flex-wrap: wrap;
}

.align-items-center {
  align-items: center;
}

.display-block {
  display: block !important;
}

.display-grid {
  display: grid;
}

.display-inline-block {
  display: inline-block;
}

.display-none {
  display: none !important;
}

.text-transform-uppercase {
  text-transform: uppercase;
}

.margin-auto {
  margin-left: auto;
  margin-right: auto;
}

.big__title {
  font-size: 36px !important;
  font-weight: bold;
}

.section__title {
  font-size: 20px !important;
  font-weight: bold;
}

.section__regular__title {
  font-size: 16px !important;
}

.section__bold__title {
  font-size: 16px;
  font-weight: 500;
}

input {
  border-radius: 6px;
}

.display-none {
  display: none !important;
}

.hide {
  visibility: hidden;
  opacity: 0;
}

// margins
.m-auto {
  margin: 0 auto !important;
}

// 10px
.mt-10 {
  margin-top: 10px;
}

.mr-10 {
  margin-right: 10px;
}

.mb-10 {
  margin-bottom: 10px;
}

.ml-10 {
  margin-left: 10px !important;
}
.ml-auto {
  margin-left: auto;
}

.mtb-10 {
  margin-top: 10px;
  margin-bottom: 10px;
}

// 15px
.mt-15 {
  margin-top: 15px;
}

.mr-15 {
  margin-right: 15px !important;
}

.mb-15 {
  margin-bottom: 15px;
}

.ml-15 {
  margin-left: 15px;
}

.mtb-15 {
  margin-top: 15px !important;
  margin-bottom: 15px !important;
}

// 15px
.mt-15 {
  margin-top: 15px;
}

.mr-15 {
  margin-right: 15px !important;
}

.mb-15 {
  margin-bottom: 15px;
}

.ml-15 {
  margin-left: 15px !important;
}

.mtb-15 {
  margin-top: 15px;
  margin-bottom: 15px;
}

// 20px
.mt-20 {
  margin-top: 20px !important;
}

.mr-20 {
  margin-right: 20px !important;
}

.mb-20 {
  margin-bottom: 20px !important;
}

.ml-20 {
  margin-left: 20px !important;
}

.mtb-20 {
  margin-top: 20px;
  margin-bottom: 20px;
}

// 30px
.mt-30 {
  margin-top: 30px !important;
}

.mr-30 {
  margin-right: 30px !important;
}

.mb-30 {
  margin-bottom: 30px !important;
}

.ml-30 {
  margin-left: 30px !important;
}

.mtb-30 {
  margin-top: 30px;
  margin-bottom: 30px;
}

// 40px
.mt-40 {
  margin-top: 40px;
}

.mr-40 {
  margin-right: 40px;
}

.mb-40 {
  margin-bottom: 40px;
}

.ml-40 {
  margin-left: 40px;
}

.mtb-40 {
  margin-top: 40px;
  margin-bottom: 40px;
}

// 50px
.mt-50 {
  margin-top: 50px !important;
}

.mr-50 {
  margin-right: 50px;
}

.mb-50 {
  margin-bottom: 50px;
}

.ml-50 {
  margin-left: 50px;
}

.mtb-50 {
  margin-top: 50px;
  margin-bottom: 50px;
}

// 60px
.mt-60 {
  margin-top: 60px !important;
}

.mr-60 {
  margin-right: 60px;
}

.mb-60 {
  margin-bottom: 60px;
}

.ml-60 {
  margin-left: 60px;
}

// paddings
.pr-0 {
  padding-right: 0;
}

//10px
.pt-10 {
  padding-top: 10px;
}

.pr-10 {
  padding-right: 10px;
}

.pb-10 {
  padding-bottom: 10px;
}

.pl-10 {
  padding-left: 10px;
}

.plr-10 {
  padding-left: 10px !important;
  padding-right: 10px !important;
}

.p1b-10 {
  padding-left: 10px;
  padding-right: 10px;
}

//

.pl-15 {
  padding-left: 15px;
}

// 20px
.p-20 {
  padding: 20px;
}

.pt-20 {
  padding-top: 20px;
}

.pr-20 {
  padding-right: 20px;
}

.pb-20 {
  padding-bottom: 20px;
}

.pl-20 {
  padding-left: 20px;
}

.plr-20 {
  padding-left: 20px !important;
  padding-right: 20px !important;
}

// 25px
.p-25 {
  padding: 25px;
}

.pt-25 {
  padding-top: 25px;
}

.pr-25 {
  padding-right: 25px;
}

.pb-25 {
  padding-bottom: 25px;
}

.pl-25 {
  padding-left: 25px;
}

.plr-25 {
  padding-left: 25px !important;
  padding-right: 25px !important;
}

// 30px
.p-30 {
  padding: 30px !important;
}

.pt-30 {
  padding-top: 30px;
}

.pr-30 {
  padding-right: 30px;
}

.pb-30 {
  padding-bottom: 30px;
}

.pl-30 {
  padding-left: 30px;
}

.plr-30 {
  padding-left: 30px !important;
  padding-right: 30px !important;
}

// 40px
.pt-40 {
  padding-top: 40px;
}

.pr-40 {
  padding-right: 40px;
}

.pb-40 {
  padding-bottom: 40px;
}

.pl-40 {
  padding-left: 40px;
}

.plr-40 {
  padding-left: 40px !important;
  padding-right: 40px !important;
}

// 50px
.pt-50 {
  padding-top: 50px;
}

.pr-50 {
  padding-right: 50px;
}

.pb-50 {
  padding-bottom: 50px;
}

.pl-50 {
  padding-left: 50px;
}

.plr-50 {
  padding-left: 50px !important;
  padding-right: 50px !important;
}

// button
.artidis-btn-md {
  height: $buttonHeight;
}

.transparent-button {
  cursor: pointer;
  background: none !important;
  border: none !important;
}

// titles
.xs-title,
.sm-title,
.md-title,
.xl-title {
  font-weight: 700;
  letter-spacing: 1px;
}

.sm-title {
  font-size: 1rem;
}

.md-title {
  font-size: 1.25rem;
}

.xl-title {
  font-size: 1.5rem;
}


// paragraphs
.p-sm {
  font-size: 0.75rem;
}

// font styles
.font-weight-bold {
  font-weight: bold;
}

.font-weight-normal {
  font-weight: normal;
}

// rotate

.rotate-90 {
  -webkit-transform: rotate(90deg);
  transform: rotate(90deg);
}

.rotate-180 {
  -webkit-transform: rotate(180deg);
  transform: rotate(180deg);
}

// flip
.flip-360 {
  -webkit-transform: scaleX(-1);
  transform: scaleX(-1);
}

// page head
.page-head-wrapper {
  min-height: 64px;
}

.page-head {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 50px;
  padding-top: .5rem;
  z-index: 10001;

  @media (max-width: $screen-medium - 1) {
    margin-bottom: 20px;
    flex-direction: column;
    justify-content: unset;
    align-items: unset;
  }

  &__left {
    &__case-identifier {
      display: none;
    }
  }

  &__right {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    @media (max-width: $screen-medium - 1) {
      justify-content: unset;
      align-items: unset;
      flex-direction: column;
      margin-top: 20px;
      button {
        width: 100%;
        margin: 0 0 10px 0 !important;
      }
    }
  }

  &.fixed {
    position: fixed;
    top: 72px;
    background-color: $white;
    border-bottom: 2px solid #e7e7e7;
    z-index: 9999;
    justify-content: space-between;
    padding: 0.8rem 1rem;
    margin-left: -13px;

    @media (max-width: $screen-extra-large - 1) {
      width: 100% !important;
      top: 110px !important;
      padding-left: 6rem;
      left: 0;
      margin: 0;
    }

    @media (max-width: $screen-medium - 1) {
      padding: 2rem;
    }

    .page-head__left {
      &__case-identifier {
        display: flex;
        flex-direction: column;
      }

      div:not(.page-head__left__case-identifier) {
        display: none;
      }
    }
  }
}

// qr code
.qr-code {
  display: flex;
  justify-content: center;

  canvas {
    width: 300px !important;
    height: 300px !important;
  }
}

// drag and drop
.drag-drop-area {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 200px;
  max-height: 100%;
  max-width: 100%;
  border: 4px dashed #e3e3e3;

  &__content {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    text-align: center;

    button {
      margin-bottom: 10px;
    }
  }

  p {
    font-style: italic;
    margin: 0 0 5px 0;
  }
}


// modal header

.custom-modal {
  &__head {
    margin-bottom: 20px;

    &__content {
      display: flex;
      justify-content: space-between;
      align-items: center;

      &__button-close {
        i {
          font-size: 20px;
        }
      }
    }
  }

  .mat-divider {
    margin: 0 -24px;
  }
}

// custom tabs
.custom-tabs {
  margin-bottom: 1.2rem;

  &__list {
    list-style: none;
    margin: 0;
    padding: 0;
    display: flex;

    &__item {
      display: flex;
      align-items: center;
      justify-content: center;

      &.active {
        color: $primary;
        border-bottom: 3px solid $primary;
      }
    }
  }
}

// password strength component
.input-password {
  .mat-focused {
    &.mat-form-field-appearance-outline {
      &.mat-accent {
        .mat-form-field-outline,
        .mat-form-field-label {
          color: $orange;
        }
      }

      &.mat-primary {
        .mat-form-field-outline,
        .mat-form-field-label {
          color: $green;
        }
      }
    }
  }

  .password-strength {
    &__password-length {
      display: flex;
      justify-content: flex-end;
      font-size: 0.75rem;
      margin-bottom: 0.5rem;
    }

    .mat-mdc-progress-bar {
      .mdc-linear-progress__buffer-bar {
        border-color: $password-strength-progress-bar-background-color !important;
      }

      &.mat-accent {
        .mdc-linear-progress__bar-inner {
          border-color: $orange;
        }
      }

      &.mat-primary {
        .mdc-linear-progress__bar-inner {
          border-color: $green;
        }
      }
    }

    &__info {
      background-color: $password-strength-info-background-color;
      border-bottom-left-radius: 4px;
      border-bottom-right-radius: 4px;

      .mat-mdc-card {
        background: none;
        padding: 0 1rem 1rem 1rem;
        justify-content: unset;
      }

      .mat-icon {
        &.mat-primary {
          color: $green;
        }
      }

      &__toggle {
        display: flex;
        align-items: center;
        justify-content: space-between;
        height: 2.75rem;
        border-bottom-left-radius: 4px;
        border-bottom-right-radius: 4px;
        cursor: pointer;
        padding: 0 1rem;
        background: $primary;
        color: $white;

        &--toggled {
          color: $black;
          border-radius: 0;
          background: $password-strength-info-background-color;
        }
      }
    }
  }
}

// richtext quil editor
.ql-toolbar.ql-snow + .ql-container.ql-snow {
  min-height: 150px;
}

// fix padding
.mat-mdc-form-field-has-icon-suffix .mat-mdc-text-field-wrapper {
  padding-right: .75rem !important;
}

// fix spinner color
.mat-mdc-progress-spinner {
  circle {
    stroke: $primary;
  }
}

//Adjust the height of the mat-form-field
.mat-mdc-form-field-infix{
  --mat-form-field-container-height: 50px;
  --mat-form-field-filled-with-label-container-padding-top: 22px;
  --mat-form-field-filled-with-label-container-padding-bottom: 4px
}
