.mat-mdc-card {
  box-shadow: none !important;
  border-radius: 5px !important;

  .mat-mdc-card-content {
    &:first-child {
      padding: .5rem;
    }
  }

  .mat-mdc-card-footer {
    padding: 0 .5rem .5rem .5rem;
  }
}
