@use '@angular/material' as mat;
@use '@mat-datetimepicker/core/datetimepicker/datetimepicker-theme' as matdp;
@use 'sass:map';
@import "theme-colors";
@include mat.core();

@import '@mat-datetimepicker/core/datetimepicker/datetimepicker-theme';

/* ======== artidis material custom theme ======== */
$artidis-custom-primary: mat.define-palette($mat-artidis-primary);
$artidis-custom-accent: mat.define-palette(mat.$pink-palette, 100, 500, A100);
$artidis-custom-warn: mat.define-palette(mat.$red-palette);
$custom-typography: mat.define-typography-config($font-family: "Roboto");
@include mat.all-component-typographies($custom-typography);

// Light theme
$artidis-theme: mat.define-light-theme((color: (primary: $artidis-custom-primary,
  accent: $artidis-custom-accent,
  warn: $artidis-custom-warn,
)));

// Main theme defination
@include mat.all-component-themes($artidis-theme);

// Using the $theme variable from the pre-built theme you can call the theming function
@include mat-datetimepicker-theme($artidis-theme);

//@include mat.private-form-field-density(-1)
