// main colors
$white: #FCFCFC;
$primary: #5f8ab4;
$primarySecond: #baccde;
$purple: #7c6afe;
$lightRed: #ff808b;
$lightGray: #878787;
$green: #33D9B2;
$red: #ff7979;
$black: #000000;
$gray: #797f87;
$lightBlack: #4b4b4b;
$orange: #FFB142;
$yellow: #F9C02C;

// text colors
$primaryTextColor: #222222;
$secondaryTextColor: #6c747c;
// components colors
$lightBorder: rgba(0, 0, 0, 0.12);
$darkBorder: #4e5360;

// study nav
$study-nav-group-background: #3a638c;
$study-nav-subgroups-background: #f1f2f3;

// sidenav
$sidenav-background-color: $primary;
$sidenav-item-background-active-color: #3a638c;

// task
$task-state-created-color: $black;
$task-state-completed-color: #2DAE90;
$task-state-published-color: $primary;
$task-state-aborted-color: $red;

$task-state-background-color: #f1f2f3;

// password strength
$password-strength-info-background-color: #F1F2F3;
$password-strength-progress-bar-background-color: #F1F2F3;

// template import
$legend-group-color: $primary;
$legend-subgroup-color: #CACACA;
$legend-parameter-color: #F1F2F3;
// divider color
$divider-border-color: #E8E8E8;


.border-primary {
  background: none;
  border: 2px solid $primary !important;
}

.border-right-dark {
  background: none;
  border-right: 1px solid $darkBorder !important;
}

// backgrounds
.background-none {
  background: none;
}
.background-primary {
  background-color: $primary;
}

.background-white {
  background-color: $white;
}

.background-red {
  background-color: $red;
}

.background-green {
  background-color: $green;
}


.background-lightred {
  background-color: $lightRed;
}

// text colors
.primary-text-color {
  color: $primary;
  &.hoverable {
    cursor: pointer;
    &:hover {
      text-decoration: underline;
    }
  }
}

.white-text {
  color: $white;
}

.primary-text {
  color: $primaryTextColor;
}

.secondary-text {
  color: $secondaryTextColor;
}

.red-text {
  color: $red;
}

.green-text {
  color: $green;
}

// group, study statuses
.status-filled {
  background-color: #33d9b2;
}

.status-partially-filled {
  background-color: #ffb142;
}

.status-untouched {
  background-color: #ff7979;
}

// audit statuses
.status-pending {
  background-color: $primary;
}

.status-approved {
  background-color: $green;
}

.status-canceled {
  background-color: black;
}

.status-rejected {
  background-color: $red;
}
