.mat-mdc-menu-panel,
.cdk-menu-group {
  --mat-menu-container-border-color: #DBDBDB;
  --mat-menu-container-shape: .625rem;
  --mat-menu-container-color: var(--background-color-white);
  --mat-menu-item-label-text-size: var(--paragraph-large-font-size);
  --mat-menu-item-label-text-line-height: 130%;
  -webkit-filter: drop-shadow(0px 4px 20px rgba(0, 0, 0, 0.05));
  filter: drop-shadow(0px 4px 20px rgba(0, 0, 0, 0.05));
  box-shadow: unset !important;
  border: unset !important;

  &.v2 {
    background: white;
    box-shadow: 0 10px 10px 0 rgba(72, 73, 76, 0.24), 0 3px 3px 0 rgba(72, 73, 76, 0.16), 0 1px 2px 0 rgba(72, 73, 76, 0.08);
    border: 1px solid #D9D9DB;
    border-radius: 12px;
    padding: .25rem;
  }

  &.case-list-view-menu {
    width: 200px;

    .mat-mdc-menu-item {
      height: 32px;
      padding: 10px;
      font-family: Inter, sans-serif;
      font-size: 14px;
      font-weight: 400;
      line-height: 16px;
      letter-spacing: -0.083px;
      color: rgba(20, 21, 22, 1);
      min-height: auto;

      svg-icon {
        margin-right: 4px;
        position: relative;
        top: -1px;

        path {
          fill: #141516;
        }
      }
    }
  }
}

