@import "@angular/cdk/overlay-prebuilt.css";
*, ::after, ::before {
  box-sizing: border-box;
}

markdown {
  /* Style for table cells */
  /* Style for the table header */
  /* Adding some hover effects for table rows */
}
markdown ul h1, markdown ul h2, markdown ul h3, markdown ul h4, markdown ul h5, markdown ul h6,
markdown ol h1, markdown ol h2, markdown ol h3, markdown ol h4, markdown ol h5, markdown ol h6,
markdown p {
  margin-bottom: 12px;
  font-family: Inter, sans-serif;
  font-size: 14px !important;
  font-weight: 400 !important;
  line-height: 18px;
  letter-spacing: -0.0839px;
  color: #000;
  margin-top: 0;
}
markdown table {
  width: calc(100% - 2px);
  border-collapse: collapse;
  border-radius: 6px;
  overflow: hidden;
  box-shadow: 0 0 0 1px #D9D9DB !important;
  margin: 1px 1px 12px 1px;
}
markdown table, markdown th, markdown td {
  border: 1px solid #D9D9DB;
  padding: 8px;
  text-align: left;
}
markdown th {
  background-color: #efefef;
}
markdown tr:hover {
  background-color: #f2f2f2;
}
markdown ol, markdown ul {
  margin-bottom: 0;
}
markdown ol li, markdown ul li {
  margin-bottom: 10px;
}

:root {
  --heading-h1-size: 3rem;
  --heading-h2-size: 2.5rem;
  --heading-h3-size: 2rem;
  --heading-h4-size: 1.5rem;
  --heading-h5-size: 1.25rem;
  --heading-h6-size: 1.125rem;
  --paragraph-small-font-size: .625rem;
  --paragraph-regular-font-size: .75rem;
  --paragraph-large-font-size: .875rem;
  --paragraph-extra-large-font-size: 1rem;
  --main-dark-color: #28435D;
  --main-base-color: #5F8AB4;
  --main-light-color: #75A4D1;
  --neutral-dark-color: #414447;
  --neutral-base-color: #868686;
  --neutral-light-color: #CACACA;
  --status-color-error: #F45050;
  --status-color-error-light: #f9a6ad;
  --status-color-warning: #FB8D00;
  --status-color-warning-light: #f9c17a;
  --status-color-success: #33D9B2;
  --status-color-success-light: #EBFBF7;
  --backgorund-color-red: #F45050;
  --backgorund-color-light-blue: #F4FAFF;
  --background-color-light-green: #33D9B2;
  --background-color-gray: #F1F2F3;
  --background-color-light-gray: #FAFAFA;
  --background-color-white: #FCFCFC;
  --study-state-inPreparation-background-color: #EBEBEC;
  --study-state-inPreparation-text-color: #48494C;
  --study-state-active-background-color: #B3FF96;
  --study-state-active-text-color: #0A5500;
  --study-state-paused-background-color: #FFEC6D;
  --study-state-paused-text-color: #5D4500;
  --study-state-aborted-background-color: #B3FF96;
  --study-state-aborted-text-color: #970000;
  --study-state-completed-background-color: #83FFF7;
  --study-state-completed-text-color: #00524D;
  --bs-body-font-size: 14px;
}

.row {
  --bs-gutter-x: 1rem;
}

body:not(.study-settings-body) h2 {
  font-weight: 700;
  font-size: 1.5rem;
}
body:not(.study-settings-body) h3 {
  font-weight: 700;
  font-size: 1.25rem;
  letter-spacing: 1px;
}
body:not(.study-settings-body) h4 {
  font-size: 1.75rem;
  font-weight: 700;
  letter-spacing: 1px;
}
body:not(.study-settings-body) .xs-title,
body:not(.study-settings-body) .sm-title,
body:not(.study-settings-body) .md-title,
body:not(.study-settings-body) .xl-title,
body:not(.study-settings-body) .xxl-title {
  font-weight: 700;
  letter-spacing: 1px;
}
body:not(.study-settings-body) .xs-title {
  font-size: 0.75rem !important;
}
body:not(.study-settings-body) .sm-title {
  font-size: 1rem;
}
body:not(.study-settings-body) .md-title {
  font-size: 1.25rem;
}
body:not(.study-settings-body) .xl-title {
  font-size: 1.5rem;
}
body:not(.study-settings-body) .xxl-title {
  font-size: 1.75rem;
}

body.study-settings-body {
  font-family: "Roboto", Arial, sans-serif !important;
  font-size: 16px !important;
}
body.study-settings-body h1 {
  font-size: var(--heading-h1-size);
}
body.study-settings-body h2 {
  font-size: var(--heading-h2-size);
}
body.study-settings-body h3 {
  font-size: var(--heading-h3-size);
}
body.study-settings-body h4 {
  font-size: var(--heading-h4-size);
}
body.study-settings-body h5 {
  font-size: var(--heading-h4-size);
}
body.study-settings-body h1, body.study-settings-body h2, body.study-settings-body h3, body.study-settings-body h4, body.study-settings-body h5, body.study-settings-body h6 {
  font-weight: 700;
  letter-spacing: 1.6px;
  line-height: 120%;
}
body.study-settings-body p {
  line-height: 150%;
  letter-spacing: 0.6px;
}
body.study-settings-body .body-1 {
  line-height: 150%;
  letter-spacing: 0.6px;
}
body.study-settings-body .body-2 {
  line-height: 150%;
  letter-spacing: 0.6px;
}
body.study-settings-body .body-3 {
  line-height: 150%;
  letter-spacing: 0.6px;
}
body.study-settings-body .body-4 {
  line-height: 150%;
  letter-spacing: 0.6px;
}

.body-1 {
  font-size: var(--paragraph-small-font-size);
}

.body-2 {
  font-size: var(--paragraph-regular-font-size);
}

.body-3 {
  font-size: var(--paragraph-large-font-size);
}

.body-4 {
  font-size: var(--paragraph-extra-large-font-size);
}

:root {
  --mdc-typography-body1-font-size: 14px;
  --mdc-typography-body1-letter-spacing: normal;
}

.mat-mdc-checkbox.mat-primary {
  --mdc-checkbox-unselected-icon-color: #CACACA;
  --mdc-checkbox-selected-checkmark-color: #FFFFFF;
}

.mdc-button {
  --mdc-typography-button-letter-spacing: normal;
}

.mat-mdc-raised-button:not(:disabled),
.mat-mdc-unelevated-button:not(:disabled) {
  color: var(--background-color-white);
}
.mat-mdc-raised-button:not(:disabled) svg path,
.mat-mdc-unelevated-button:not(:disabled) svg path {
  fill: var(--background-color-white);
}
.mat-mdc-raised-button:not(:disabled).mat-primary,
.mat-mdc-unelevated-button:not(:disabled).mat-primary {
  color: white;
  background-color: var(--main-base-color);
}
.mat-mdc-raised-button:not(:disabled).mat-warn,
.mat-mdc-unelevated-button:not(:disabled).mat-warn {
  background-color: var(--backgorund-color-red);
}
.mat-mdc-raised-button:not(:disabled).button-success,
.mat-mdc-unelevated-button:not(:disabled).button-success {
  background-color: var(--background-color-light-green);
}

.mat-mdc-outlined-button:not(:disabled).mat-primary {
  color: var(--main-base-color);
  border: 2px solid var(--main-base-color);
}
.mat-mdc-outlined-button:not(:disabled).mat-primary:hover {
  background-color: var(--main-base-color);
}
.mat-mdc-outlined-button:not(:disabled).mat-warn {
  color: var(--backgorund-color-red);
  border: 2px solid var(--backgorund-color-red);
}
.mat-mdc-outlined-button:not(:disabled).mat-warn:hover {
  background-color: var(--backgorund-color-red);
}
.mat-mdc-outlined-button:not(:disabled).button-success {
  color: var(--background-color-light-green);
  border: 2px solid var(--background-color-light-green);
}
.mat-mdc-outlined-button:not(:disabled).button-success:hover {
  background-color: var(--background-color-light-green);
}
.mat-mdc-outlined-button:not(:disabled):hover {
  color: var(--background-color-white);
  border-color: transparent;
}
.mat-mdc-outlined-button:not(:disabled):hover svg path {
  fill: var(--background-color-white);
}

.mat-mdc-icon-button.mat-mdc-button-base {
  padding: 0;
  font-size: initial;
  font-weight: normal;
}
.mat-mdc-icon-button.mat-mdc-button-base .mdc-icon-button__ripple {
  border-radius: unset;
}
.mat-mdc-icon-button.mat-mdc-button-base.mat-icon-sm-button {
  width: 1rem;
  height: 1rem !important;
}
.mat-mdc-icon-button.mat-mdc-button-base.mat-icon-md-button {
  width: 1.5rem;
  height: 1.5rem !important;
}
.mat-mdc-icon-button.mat-mdc-button-base.mat-icon-lg-button {
  width: 2rem;
  height: 2rem !important;
}

.mat-mdc-list-base {
  --mdc-list-list-item-one-line-container-height: 44px;
  --mdc-list-list-item-label-text-size: 14px;
}

.mdc-list-item__primary-text {
  --mdc-list-list-item-label-text-size: 14px;
}

.mat-mdc-card {
  box-shadow: none !important;
  border-radius: 5px !important;
}
.mat-mdc-card .mat-mdc-card-content:first-child {
  padding: 0.5rem;
}
.mat-mdc-card .mat-mdc-card-footer {
  padding: 0 0.5rem 0.5rem 0.5rem;
}

.mdc-tooltip .mdc-tooltip__surface {
  color: var(--main-dark-color) !important;
  font-size: 12px;
  padding: 0.5rem;
  border: 2px solid var(--main-dark-color) !important;
  background: var(--background-color-white) !important;
  border-radius: 10px !important;
  text-align: center !important;
}
.mdc-tooltip::before {
  content: "";
  border-bottom: 20px solid rgba(0, 0, 0, 0);
  clip-path: polygon(0 0, 100% 0, 100% calc(100% - 20px), calc(50% - 20px) calc(100% - 20px), var(50%) 100%, calc(50% + 20px) calc(100% - 20px), 0 calc(100% - 20px));
}

.mat-mdc-menu-panel,
.cdk-menu-group {
  --mat-menu-container-border-color: #DBDBDB;
  --mat-menu-container-shape: .625rem;
  --mat-menu-container-color: var(--background-color-white);
  --mat-menu-item-label-text-size: var(--paragraph-large-font-size);
  --mat-menu-item-label-text-line-height: 130%;
  -webkit-filter: drop-shadow(0px 4px 20px rgba(0, 0, 0, 0.05));
  filter: drop-shadow(0px 4px 20px rgba(0, 0, 0, 0.05));
  box-shadow: unset !important;
  border: unset !important;
}
.mat-mdc-menu-panel.v2,
.cdk-menu-group.v2 {
  background: white;
  box-shadow: 0 10px 10px 0 rgba(72, 73, 76, 0.24), 0 3px 3px 0 rgba(72, 73, 76, 0.16), 0 1px 2px 0 rgba(72, 73, 76, 0.08);
  border: 1px solid #D9D9DB;
  border-radius: 12px;
  padding: 0.25rem;
}
.mat-mdc-menu-panel.case-list-view-menu,
.cdk-menu-group.case-list-view-menu {
  width: 200px;
}
.mat-mdc-menu-panel.case-list-view-menu .mat-mdc-menu-item,
.cdk-menu-group.case-list-view-menu .mat-mdc-menu-item {
  height: 32px;
  padding: 10px;
  font-family: Inter, sans-serif;
  font-size: 14px;
  font-weight: 400;
  line-height: 16px;
  letter-spacing: -0.083px;
  color: rgb(20, 21, 22);
  min-height: auto;
}
.mat-mdc-menu-panel.case-list-view-menu .mat-mdc-menu-item svg-icon,
.cdk-menu-group.case-list-view-menu .mat-mdc-menu-item svg-icon {
  margin-right: 4px;
  position: relative;
  top: -1px;
}
.mat-mdc-menu-panel.case-list-view-menu .mat-mdc-menu-item svg-icon path,
.cdk-menu-group.case-list-view-menu .mat-mdc-menu-item svg-icon path {
  fill: #141516;
}

.mat-mdc-standard-chip {
  --mdc-chip-label-text-color: var(--main-base-color);
  --mdc-chip-with-trailing-icon-trailing-icon-color: var(--main-base-color);
  --mat-icon-color: var(--main-base-color);
  --mdc-chip-elevated-container-color: #FCFCFC ;
}

.mat-mdc-chip-grid .mat-mdc-standard-chip {
  border-radius: 0.5rem !important;
}
.mat-mdc-chip-grid .mat-mdc-standard-chip .mat-chip-trailing-icon {
  line-height: 150%;
}

.mdc-evolution-chip-set .mdc-evolution-chip {
  margin: 0 4px !important;
}

:not(.meta-menu) .mdc-switch {
  width: 28px;
  height: 16px;
}
:not(.meta-menu) .mdc-switch .mdc-switch__track {
  width: 28px;
  height: 16px;
  background-color: #EBEBEC;
}
:not(.meta-menu) .mdc-switch .mdc-switch__handle-track {
  width: 12px !important;
  height: 12px !important;
  top: 2px !important;
  left: 2px !important;
}
:not(.meta-menu) .mdc-switch .mdc-switch__handle {
  width: 12px !important;
  height: 12px !important;
}
:not(.meta-menu) .mdc-switch .mdc-switch__handle::before {
  background-color: #fff;
}
:not(.meta-menu) .mdc-switch .mdc-switch__icons {
  display: none;
}
:not(.meta-menu) .mdc-switch .mdc-elevation-overlay {
  background-color: #fff;
}

.stroma-tabs {
  display: flex;
  flex: 1;
  overflow: auto;
  border-bottom: 1px solid #EBEBEC;
}
.stroma-tabs .stroma-tab-item {
  height: 100%;
  color: #656669;
  font-weight: 500;
  border-radius: 6px 6px 0 0;
  border-bottom-style: solid;
  border-bottom-width: 1px;
  border-bottom-color: transparent;
  background: none;
  padding: 0 12px;
  cursor: pointer;
  white-space: nowrap;
}
.stroma-tabs .stroma-tab-item:not(:disabled):hover {
  color: #141516;
  background-color: #F4F5F5;
}
.stroma-tabs .stroma-tab-item:not(:disabled):active {
  color: #141516;
  background-color: #EBEBEC;
  -webkit-box-shadow: inset 0 1px 1px 0.5px rgb(167, 168, 171);
  -moz-box-shadow: inset 0 1px 1px 0.5px rgb(167, 168, 171);
  box-shadow: inset 0 1px 1px 0.5px rgb(167, 168, 171);
}
.stroma-tabs .stroma-tab-item:not(:disabled).active {
  color: #141516;
  border-color: #212224;
}
.stroma-tabs .stroma-tab-item:disabled {
  color: rgba(4, 6, 15, 0.3490196078);
  cursor: not-allowed;
}
.stroma-tabs-small .stroma-tab-item {
  height: 32px;
  font-size: 12px;
}
.stroma-tabs-regular .stroma-tab-item {
  height: 40px;
  font-size: 14px;
}
.stroma-tabs-medium .stroma-tab-item {
  height: 40px;
  font-size: 16px;
}
.stroma-tabs-large .stroma-tab-item {
  height: 48px;
  font-size: 20px;
}

.mat-form-field-appearance-outline .mat-mdc-text-field-wrapper {
  background-color: rgba(0, 0, 0, 0.04) !important;
  border-radius: 4px;
}

.mdc-line-ripple {
  display: none !important;
}

.phone-number-form-field .country-selector {
  left: -15px !important;
  opacity: 1 !important;
}
.phone-number-form-field .mdc-floating-label {
  left: 80px !important;
}
.phone-number-form-field .mdc-floating-label.mdc-floating-label--float-above {
  display: none;
}
.phone-number-form-field input {
  padding-left: 80px !important;
  position: relative;
  top: -7px;
}

.error {
  z-index: 99;
}

.alert {
  position: fixed;
  left: 50%;
  top: 2.5rem;
  transform: translate(-50%, 0);
  z-index: 99999;
  min-width: 150px;
  padding: calc(1rem - 2px) 1rem;
  border: 2px solid transparent;
  border-radius: 0.625rem;
  font-size: var(--paragraph-extra-large-font-size);
  font-weight: 700;
  --webkit-filter: drop-shadow(0px 4px 20px rgba(51, 217, 178, 0.10));
  filter: drop-shadow(0px 4px 20px rgba(51, 217, 178, 0.1));
  backdrop-filter: blur(20px);
  text-align: center;
}
.alert-success {
  background-color: var(--status-color-success-light);
  border-color: var(--status-color-success);
}
.alert-warning {
  background-color: var(--status-color-warning-light);
  border-color: var(--status-color-warning);
}
.alert-danger {
  background-color: var(--status-color-error-light);
  border-color: var(--status-color-error);
}
.alert p {
  padding: 0;
  margin: 0;
}
.alert i {
  padding-right: 5px;
  vertical-align: middle;
  font-size: 24px;
}
.alert .close-alert {
  -webkit-appearance: none;
  position: relative;
  float: right;
  padding: 0;
  border: 0;
  cursor: pointer;
  color: inherit;
  background: 0 0;
  font-size: 21px;
  line-height: 1;
  font-weight: bold;
  text-shadow: 0 1px 0 rgba(255, 255, 255, 0.7);
  filter: alpha(opacity=40);
  opacity: 0.4;
}
.alert .close-alert:hover {
  filter: alpha(opacity=70);
  opacity: 0.7;
}

.border-primary {
  background: none;
  border: 2px solid #5f8ab4 !important;
}

.border-right-dark {
  background: none;
  border-right: 1px solid #4e5360 !important;
}

.background-none {
  background: none;
}

.background-primary {
  background-color: #5f8ab4;
}

.background-white {
  background-color: #FCFCFC;
}

.background-red {
  background-color: #ff7979;
}

.background-green {
  background-color: #33D9B2;
}

.background-lightred {
  background-color: #ff808b;
}

.primary-text-color {
  color: #5f8ab4;
}
.primary-text-color.hoverable {
  cursor: pointer;
}
.primary-text-color.hoverable:hover {
  text-decoration: underline;
}

.white-text {
  color: #FCFCFC;
}

.primary-text {
  color: #222222;
}

.secondary-text {
  color: #6c747c;
}

.red-text {
  color: #ff7979;
}

.green-text {
  color: #33D9B2;
}

.status-filled {
  background-color: #33d9b2;
}

.status-partially-filled {
  background-color: #ffb142;
}

.status-untouched {
  background-color: #ff7979;
}

.status-pending {
  background-color: #5f8ab4;
}

.status-approved {
  background-color: #33D9B2;
}

.status-canceled {
  background-color: black;
}

.status-rejected {
  background-color: #ff7979;
}

body {
  margin: 0;
  font-family: "Roboto", Arial, Helvetica, sans-serif !important;
  background-color: var(--background-color-white);
}

#app-body.has-backdrop {
  overflow: hidden;
}

.mat-drawer-backdrop.mat-drawer-shown {
  position: fixed;
}

.header {
  justify-content: space-between;
}

.mat-drawer-content {
  background-color: var(--background-color-white);
  overflow: hidden !important;
}

.mat-nav-list .mat-mdc-list-item {
  font-size: 15px;
}

.nav-tool-items {
  display: inline-block;
  margin-right: 13px;
}

.mat-mdc-list-item.active {
  background: rgba(0, 0, 0, 0.04);
}

.mat-sidenav {
  background-color: #f2f2f2;
  font-size: 14px;
  width: 305px;
  border-right: solid 1px rgba(0, 0, 0, 0.12);
  padding: 15px;
  display: inherit;
  box-shadow: none !important;
}

.mat-drawer-inner-container {
  overflow-x: hidden !important;
}

.mat-drawer-backdrop {
  height: 100vh;
}

.header {
  position: sticky;
  position: -webkit-sticky;
  top: 0;
  z-index: 1000;
}

mat-sidenav mat-icon {
  margin-right: 12px;
}

.hamburger {
  margin-top: 5px;
  cursor: pointer;
}

.mat-mdc-radio-button,
.mat-mdc-radio-group {
  margin-right: 25px;
}

.controlers-wrapper > * {
  width: 100%;
  padding: 0;
}

.misc-bottom-padding {
  margin: 8px 0 10px;
}

.misc-bottom-padding mat-label {
  margin-right: 15px;
}

mat-radio-group mat-radio-button {
  margin-left: 5px;
}

.mat-mdc-list-base .mat-mdc-list-item {
  font-size: 13px !important;
}

.inner-wrapper {
  padding: 15px 0 130px;
  width: 100%;
}

.inner-wrapper mat-card {
  display: inline-block;
  margin: 0 6% 0 0;
  vertical-align: top;
  width: 44%;
}

.full-wrapper {
  width: 100%;
}

.multiple-items {
  position: relative;
}

.multiple-items .tooltip-info {
  right: 0;
  top: 7px;
  cursor: pointer;
  color: #a1a7c7;
  position: absolute;
  font-size: 20px;
}

body .push-right {
  margin-right: 10px;
}

.no-data {
  text-align: center;
  padding-top: 30px;
  color: #6c75a9;
}

.example-card {
  max-width: 400px;
}

.title-group {
  margin-bottom: 25px;
}

.card-deck-container {
  width: 100%;
  max-width: 1200px;
  position: relative;
  border-radius: 2px;
  padding: 10px 10px 30px;
  margin: 10px 10px 10px 10px;
  background-color: #f5f5f5;
}

.card-item {
  padding: 3px 3px 3px 3px;
}

mat-grid-tile {
  background: lightblue;
}

@media (max-width: 1024px) {
  .inner-wrapper mat-card {
    width: 100%;
  }
  .misc-bottom-padding mat-label {
    display: block;
    padding-bottom: 10px;
  }
  .mat-nav-list .mat-mdc-list-item {
    font-size: 14px;
  }
}
@media (max-width: 767px) {
  .nav-tool-items {
    margin-right: 0;
  }
  .hamburger {
    visibility: visible !important;
  }
  .container {
    max-width: 100%;
  }
}
.inline-logo {
  color: #fff;
  font-style: italic;
  vertical-align: middle;
  margin-top: 10px;
}

.home-logo a {
  text-decoration: none;
}

.lock-btn,
.clickable {
  cursor: pointer;
}

.quick-access-card {
  cursor: pointer;
}

.navigation__templates__dropdown {
  width: 158px;
}

.navigation__notifications__dropdown {
  width: 280px;
  max-height: 380px !important;
  margin-top: 14px;
  margin-left: -70px;
}
.navigation__notifications__dropdown::before {
  content: "";
  position: absolute;
  top: 0;
  left: 12px;
  width: 0;
  height: 0;
  border-style: solid;
  border-width: 0 10px 10px 10px;
  border-color: transparent transparent var(--background-color-white) transparent;
}
.navigation__notifications__dropdown .mat-mdc-menu-content:not(:empty) {
  padding: 0;
}

.navigation__user-profile__dropdown {
  width: 175px;
}

.cockpit-input-dialog {
  width: 100%;
}

.mat-mdc-card-header-text {
  margin: 0 !important;
}

.profile-image-wrapper {
  width: 24px;
  height: 24px;
  border-radius: 100%;
  display: inline-block;
  background-color: #5f8ab4;
  background-size: cover;
  position: relative;
}
.profile-image-wrapper i {
  color: rgba(252, 252, 252, 0.7);
  font-size: 20px;
  position: absolute;
  bottom: -2px;
  left: 5px;
}

.user-avatar {
  width: 24px;
  height: 24px;
  border-radius: 100%;
}

body > .cdk-drag {
  opacity: 0;
}

body > .cdk-drag-preview {
  opacity: 1;
}

@media (max-width: 1024px) {
  .aritids-table-wrapper {
    max-width: 100%;
    overflow: auto;
  }
  .mat-mdc-table {
    width: 1200px;
  }
}
input[type=number] {
  -moz-appearance: textfield;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

html.cdk-global-scrollblock {
  position: static !important;
  width: initial !important;
  overflow: hidden !important;
}

html.cdk-global-scrollblock body {
  width: 100%;
}

.xng-breadcrumb-trail {
  margin: 0;
}

.xng-breadcrumb-link {
  cursor: pointer;
}

.float-end {
  float: right;
}

.country-search {
  position: sticky;
  top: 0;
  left: 0;
  background-color: #FCFCFC;
  z-index: 1;
  border: none;
  border-bottom: 1px solid #e3e3e3;
  border-radius: 0;
  outline: none;
}

.image-view-modal .mat-mdc-dialog-container,
.image-view-modal .mat-mdc-dialog-container .mdc-dialog__surface,
.video-player-modal .mat-mdc-dialog-container,
.video-player-modal .mat-mdc-dialog-container .mdc-dialog__surface {
  overflow: hidden;
}

.cdk-overlay-pane.mat-mdc-dialog-panel.image-view-modal {
  max-width: 100%;
}

.studies-menu {
  width: 400px;
  max-width: 400px !important;
}
.studies-menu .search-studies {
  padding: 0 1rem;
}
.studies-menu .study-list {
  max-height: 326px;
  overflow: auto;
}
.studies-menu .mdc-list-item__primary-text {
  flex: 1;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.studies-menu .study {
  display: flex;
}
.studies-menu .study .mat-mdc-menu-item-text {
  flex: 1;
}
.studies-menu .study .study-name {
  flex: 0 0 45%;
  width: 45%;
}
.studies-menu .study .roles-list {
  flex: 0 0 45%;
  width: 45%;
}

.study-template-import .mdc-checkbox__ripple {
  display: none;
}

.mat-badge-content {
  color: #fff !important;
  background: #5f8ab4 !important;
}

.meta-menu {
  font-size: 12px;
  max-width: 260px !important;
  min-width: 200px !important;
  -webkit-box-shadow: 0 0 6px 0 rgba(0, 0, 0, 0.1) !important;
  box-shadow: 0 0 6px 0 rgba(0, 0, 0, 0.1) !important;
}
.meta-menu .meta-menu-options {
  border-bottom: 1px solid #e0e0e0;
}
.meta-menu .mat-mdc-menu-item {
  height: 32px;
  min-height: 32px;
  padding-left: 8px;
  padding-right: 8px;
}
.meta-menu .mat-mdc-menu-item .mat-mdc-menu-item-text {
  width: 100%;
}
.meta-menu .mat-mdc-menu-item .mdc-switch {
  width: 28px;
  height: 16px;
}
.meta-menu .mat-mdc-menu-item .mdc-switch .mdc-switch__track {
  width: 28px;
  height: 16px;
  background-color: #EBEBEC;
}
.meta-menu .mat-mdc-menu-item .mdc-switch .mdc-switch__handle-track {
  width: 12px;
  height: 12px;
  top: 2px;
  left: 2px;
}
.meta-menu .mat-mdc-menu-item .mdc-switch .mdc-switch__handle {
  width: 12px;
  height: 12px;
}
.meta-menu .mat-mdc-menu-item .mdc-switch .mdc-switch__handle::before {
  background-color: #fff;
}
.meta-menu .mat-mdc-menu-item .mdc-switch .mdc-switch__icons {
  display: none;
}
.meta-menu .mat-mdc-menu-item .mdc-switch .mdc-elevation-overlay {
  background-color: #fff;
}

:root {
  --heading-h1-size: 3rem;
  --heading-h2-size: 2.5rem;
  --heading-h3-size: 2rem;
  --heading-h4-size: 1.5rem;
  --heading-h5-size: 1.25rem;
  --heading-h6-size: 1.125rem;
  --paragraph-small-font-size: .625rem;
  --paragraph-regular-font-size: .75rem;
  --paragraph-large-font-size: .875rem;
  --paragraph-extra-large-font-size: 1rem;
  --main-dark-color: #28435D;
  --main-base-color: #5F8AB4;
  --main-light-color: #75A4D1;
  --neutral-dark-color: #414447;
  --neutral-base-color: #868686;
  --neutral-light-color: #CACACA;
  --status-color-error: #F45050;
  --status-color-error-light: #f9a6ad;
  --status-color-warning: #FB8D00;
  --status-color-warning-light: #f9c17a;
  --status-color-success: #33D9B2;
  --status-color-success-light: #EBFBF7;
  --backgorund-color-red: #F45050;
  --backgorund-color-light-blue: #F4FAFF;
  --background-color-light-green: #33D9B2;
  --background-color-gray: #F1F2F3;
  --background-color-light-gray: #FAFAFA;
  --background-color-white: #FCFCFC;
  --study-state-inPreparation-background-color: #EBEBEC;
  --study-state-inPreparation-text-color: #48494C;
  --study-state-active-background-color: #B3FF96;
  --study-state-active-text-color: #0A5500;
  --study-state-paused-background-color: #FFEC6D;
  --study-state-paused-text-color: #5D4500;
  --study-state-aborted-background-color: #B3FF96;
  --study-state-aborted-text-color: #970000;
  --study-state-completed-background-color: #83FFF7;
  --study-state-completed-text-color: #00524D;
  --bs-body-font-size: 14px;
}

.row {
  --bs-gutter-x: 1rem;
}

.navigation {
  height: 72px;
  padding: 0 20px;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  box-shadow: none;
  z-index: 100;
}
.navigation__user-profile__dropdown .mat-mdc-menu-item span {
  font-size: 11px;
  position: relative;
  top: -4px;
  left: 6px;
}

:root {
  --heading-h1-size: 3rem;
  --heading-h2-size: 2.5rem;
  --heading-h3-size: 2rem;
  --heading-h4-size: 1.5rem;
  --heading-h5-size: 1.25rem;
  --heading-h6-size: 1.125rem;
  --paragraph-small-font-size: .625rem;
  --paragraph-regular-font-size: .75rem;
  --paragraph-large-font-size: .875rem;
  --paragraph-extra-large-font-size: 1rem;
  --main-dark-color: #28435D;
  --main-base-color: #5F8AB4;
  --main-light-color: #75A4D1;
  --neutral-dark-color: #414447;
  --neutral-base-color: #868686;
  --neutral-light-color: #CACACA;
  --status-color-error: #F45050;
  --status-color-error-light: #f9a6ad;
  --status-color-warning: #FB8D00;
  --status-color-warning-light: #f9c17a;
  --status-color-success: #33D9B2;
  --status-color-success-light: #EBFBF7;
  --backgorund-color-red: #F45050;
  --backgorund-color-light-blue: #F4FAFF;
  --background-color-light-green: #33D9B2;
  --background-color-gray: #F1F2F3;
  --background-color-light-gray: #FAFAFA;
  --background-color-white: #FCFCFC;
  --study-state-inPreparation-background-color: #EBEBEC;
  --study-state-inPreparation-text-color: #48494C;
  --study-state-active-background-color: #B3FF96;
  --study-state-active-text-color: #0A5500;
  --study-state-paused-background-color: #FFEC6D;
  --study-state-paused-text-color: #5D4500;
  --study-state-aborted-background-color: #B3FF96;
  --study-state-aborted-text-color: #970000;
  --study-state-completed-background-color: #83FFF7;
  --study-state-completed-text-color: #00524D;
  --bs-body-font-size: 14px;
}

.row {
  --bs-gutter-x: 1rem;
}

.mat-mdc-table,
table {
  width: 100%;
  box-shadow: none !important;
}

.mat-mdc-table {
  border: 1px solid #e0e0e0 !important;
}
.mat-mdc-table .mat-mdc-header-row .mat-mdc-header-cell {
  color: #000;
  font-weight: 700;
  height: 49px !important;
  background: #f8f8f8;
}
.mat-mdc-table .mat-mdc-header-row .mat-mdc-header-cell.mat-column-actions {
  text-align: right;
}
.mat-mdc-table .mat-mdc-header-row .mat-mdc-header-cell.right .mat-sort-header-container {
  position: relative;
  left: 20px;
  justify-content: flex-end;
}
.mat-mdc-table .mat-mdc-row .mat-mdc-cell {
  color: #222;
  height: 49px !important;
}
.mat-mdc-table .mat-mdc-row .mat-mdc-cell.mat-column-actions > div {
  display: flex;
  justify-content: flex-end;
  align-items: center;
}
.mat-mdc-table .mat-mdc-row .mat-mdc-cell .td-label {
  display: none;
}
.mat-mdc-table .mat-mdc-row .mat-mdc-cell button {
  height: 36px;
}
.mat-mdc-table .mat-mdc-row:hover {
  background-color: #e0e0e0;
}
@media (max-width: 1199px) {
  .mat-mdc-table.responsive {
    border: none;
    white-space: normal !important;
  }
  .mat-mdc-table.responsive .mat-mdc-header-row {
    display: none;
  }
  .mat-mdc-table.responsive .mat-mdc-row {
    min-height: auto;
    height: auto;
    width: 100%;
    border: 1px solid #e0e0e0;
    padding: 20px;
    margin-bottom: 20px;
    align-items: unset;
    display: flex;
    flex-direction: column;
    border-radius: 4px;
  }
  .mat-mdc-table.responsive .mat-mdc-row .mat-mdc-cell {
    height: auto !important;
    border: unset;
    margin-bottom: 10px;
    text-align: left !important;
  }
  .mat-mdc-table.responsive .mat-mdc-row .mat-mdc-cell:first-of-type, .mat-mdc-table.responsive .mat-mdc-row .mat-mdc-cell:last-of-type {
    padding: 0 !important;
  }
  .mat-mdc-table.responsive .mat-mdc-row .mat-mdc-cell .td-label {
    font-weight: bold;
    display: inline-block;
    margin-right: 5px;
  }
  .mat-mdc-table.responsive .mat-mdc-row:hover {
    background-color: transparent;
  }
}
.mat-mdc-table.v2 {
  border-collapse: separate;
  border-spacing: 0;
}
.mat-mdc-table.v2 .mat-mdc-header-row .mat-mdc-header-cell {
  color: #656669;
  font-size: 14px;
  font-weight: 500;
  border-top: 1px solid #D9D9DB;
  background: unset;
  height: 56px !important;
  padding-top: 0.25rem;
  padding-bottom: 0.25rem;
}
.mat-mdc-table.v2 .mat-mdc-header-row .mat-mdc-header-cell:first-child {
  border-left: 1px solid #D9D9DB;
  border-top-left-radius: 8px;
}
.mat-mdc-table.v2 .mat-mdc-header-row .mat-mdc-header-cell:last-child {
  border-right: 1px solid #D9D9DB;
  border-top-right-radius: 8px;
}
.mat-mdc-table.v2 .mat-mdc-header-row .mat-mdc-header-cell .sort {
  opacity: 0;
  width: 1rem;
}
.mat-mdc-table.v2 .mat-mdc-header-row .mat-mdc-header-cell.active {
  background-color: #EBEBEC;
}
.mat-mdc-table.v2 .mat-mdc-header-row .mat-mdc-header-cell:hover {
  transition: all 100ms;
  background-color: #EBEBEC;
}
.mat-mdc-table.v2 .mat-mdc-header-row .mat-mdc-header-cell:hover .sort, .mat-mdc-table.v2 .mat-mdc-header-row .mat-mdc-header-cell.active .sort {
  opacity: 1;
}
.mat-mdc-table.v2 .mat-mdc-header-row .mat-mdc-header-cell.sorted .sort {
  opacity: 1;
}
.mat-mdc-table.v2 .mdc-data-table__content .mat-mdc-row {
  height: 56px;
}
.mat-mdc-table.v2 .mdc-data-table__content .mat-mdc-row .mat-mdc-cell:first-child {
  border-left: 1px solid #D9D9DB;
}
.mat-mdc-table.v2 .mdc-data-table__content .mat-mdc-row .mat-mdc-cell:last-child {
  border-right: 1px solid #D9D9DB;
}
.mat-mdc-table.v2 .mdc-data-table__content .mat-mdc-row:last-child .mat-mdc-cell {
  border-bottom: 1px solid #D9D9DB;
}
.mat-mdc-table.v2 .mdc-data-table__content .mat-mdc-row:last-child .mat-mdc-cell:first-child {
  border-bottom-left-radius: 8px;
}
.mat-mdc-table.v2 .mdc-data-table__content .mat-mdc-row:last-child .mat-mdc-cell:last-child {
  border-bottom-right-radius: 8px;
}

.study-nav__general-menu {
  width: 250px;
  font-size: 12px;
  max-width: 240px !important;
  min-width: 200px !important;
  -webkit-box-shadow: 0 0 6px 0 rgba(0, 0, 0, 0.1) !important;
  box-shadow: 0 0 6px 0 rgba(0, 0, 0, 0.1) !important;
}

.study-main__stage .export-menu-trigger {
  width: 7.375rem;
  max-width: 100%;
  padding: 0 0.5rem;
}
.study-main__stage .export-menu-trigger i {
  margin-left: 1.25rem;
}
.study-main__stage__export-menu {
  width: 14.375rem;
  max-height: 22.188rem !important;
  padding: 1rem 0.5rem 0.5rem 0.5rem !important;
  border: none !important;
  box-shadow: -4px 4px 10px rgba(0, 0, 0, 0.12) !important;
  border-radius: 5px !important;
}
.study-main__stage__export-menu__item {
  margin-bottom: 1.6rem;
}
.study-main__stage__export-menu__item:last-child {
  margin: 0;
}
.study-main__stage__export-menu__item__title {
  font-weight: 700;
  border-bottom: 1px solid #F1F2F3;
  padding: 0 0.5rem 1rem 0.5rem;
}
.study-main__stage__export-menu__item .mat-mdc-menu-item {
  height: 2.5rem;
  line-height: 40px;
  margin-top: 0.5rem;
  color: #6C747C;
}
.study-main__stage__export-menu__item .mat-mdc-menu-item .mdc-list-item__primary-text {
  flex: 1;
  display: flex;
  justify-content: space-between;
}
.study-main__stage__export-menu__item .mat-mdc-menu-item span {
  display: flex;
  align-items: center;
  font-weight: 400;
}
.study-main__stage__export-menu__item .mat-mdc-menu-item span svg {
  margin-right: 0.5rem;
}
.study-main__stage__export-menu__item .mat-mdc-menu-item.checked span {
  color: #5f8ab4;
  font-weight: 700;
}
.study-main__stage__export-menu__item .mat-mdc-menu-item.checked svg path {
  fill: #5f8ab4;
}
.study-main__stage__export-menu .mat-mdc-radio-button {
  margin-right: 0;
}

.study-template-import .drop-list .mat-mdc-checkbox .mdc-checkbox__background,
.cdk-drag-preview .drop-list .mat-mdc-checkbox .mdc-checkbox__background {
  border-color: #fff !important;
  background-color: unset !important;
}
.study-template-import .drop-list .drop-list__item--parameter .mat-mdc-checkbox .mdc-checkbox__background,
.cdk-drag-preview .drop-list .drop-list__item--parameter .mat-mdc-checkbox .mdc-checkbox__background {
  border-color: #ccc !important;
  background-color: unset !important;
}
.study-template-import .drop-list .drop-list__item--parameter .mat-mdc-checkbox .mdc-checkbox__background .mdc-checkbox__background .mdc-checkbox__checkmark,
.cdk-drag-preview .drop-list .drop-list__item--parameter .mat-mdc-checkbox .mdc-checkbox__background .mdc-checkbox__background .mdc-checkbox__checkmark {
  stroke: #ccc !important;
}

.drop-list .mat-mdc-checkbox .mdc-checkbox__background,
.drop-list__item .mat-mdc-checkbox .mdc-checkbox__background {
  border-color: #fff !important;
  background-color: unset !important;
}
.drop-list .drop-list__item--parameter .mat-mdc-checkbox .mdc-checkbox__background,
.drop-list__item .drop-list__item--parameter .mat-mdc-checkbox .mdc-checkbox__background {
  border-color: #ccc !important;
}
.drop-list .drop-list__item--parameter .mat-mdc-checkbox .mdc-checkbox__background .mdc-checkbox__background .mdc-checkbox__checkmark,
.drop-list__item .drop-list__item--parameter .mat-mdc-checkbox .mdc-checkbox__background .mdc-checkbox__background .mdc-checkbox__checkmark {
  stroke: #ccc !important;
}

.drop-list__item__name__select-item .mat-mdc-checkbox .mdc-checkbox__background .mdc-checkbox__background .mdc-checkbox__checkmark {
  stroke: #fff !important;
}

.cdk-drag-preview {
  box-sizing: border-box;
  border-radius: 4px;
  box-shadow: unset;
}
.cdk-drag-preview.drop-list__item {
  min-height: 40px;
  border-radius: 4px;
  border: 2px solid #5f8ab4;
  font-weight: bold;
  margin-bottom: 1rem;
  padding: 0;
  display: flex;
  align-items: center;
  justify-content: space-between;
  box-sizing: border-box;
  cursor: move;
  background: #f4f7fa;
  position: relative;
}
.cdk-drag-preview.drop-list__item .drop-list__item__name {
  display: flex;
  align-items: center;
}
.cdk-drag-preview.drop-list__item .drop-list__item__name .drop-list__item__name__select-item {
  border-top-left-radius: 4px !important;
  border-bottom-left-radius: 4px !important;
  width: 2.75rem;
  height: 2.75rem;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 0.5rem;
}
.cdk-drag-preview.drop-list__item .drop-list__item__name .drop-list__item__name__select-item .mat-mdc-checkbox .mdc-checkbox__background .mdc-checkbox__background .mdc-checkbox__checkmark {
  stroke: #fff !important;
}
.cdk-drag-preview.drop-list__item .drop-list__item__name .drop-list__item__name__remove-item {
  margin-right: 0.5rem;
}
.cdk-drag-preview.drop-list__item .drop-list__item__name .drop-list__item__name__remove-item button {
  border-top-left-radius: 4px !important;
  border-bottom-left-radius: 4px !important;
  border-top-right-radius: 0 !important;
  border-bottom-right-radius: 0 !important;
  color: #FCFCFC;
  padding: 0;
  width: 2.75rem;
}
.cdk-drag-preview.drop-list__item .drop-list__item__name .drop-list__item__name__remove-item button i {
  font-size: 1.5rem;
}
.cdk-drag-preview.drop-list__item .drop-list__item__name > i {
  color: #5f8ab4;
  font-size: 0.8rem;
  margin-left: 0.5rem;
  cursor: pointer;
}
.cdk-drag-preview.drop-list__item .drop-list__item__icons {
  display: flex;
  align-items: center;
}
.cdk-drag-preview.drop-list__item .drop-list__item__icons .fa-arrows-alt {
  color: #5f8ab4;
  font-size: 1.5rem;
}
.cdk-drag-preview.drop-list__item .drop-list__item__icons .fa-chevron-down,
.cdk-drag-preview.drop-list__item .drop-list__item__icons .fa-chevron-up {
  color: #5f8ab4;
}
.cdk-drag-preview.drop-list__item.drop-list__item--group .drop-list__item__name__select-item,
.cdk-drag-preview.drop-list__item.drop-list__item--group .drop-list__item__name__remove-item {
  background-color: #5f8ab4;
}
.cdk-drag-preview.drop-list__item.drop-list__item--subgroup .drop-list__item__name__select-item,
.cdk-drag-preview.drop-list__item.drop-list__item--subgroup .drop-list__item__name__remove-item {
  background-color: #CACACA;
}
.cdk-drag-preview.drop-list__item.drop-list__item--parameter .drop-list__item__name__select-item,
.cdk-drag-preview.drop-list__item.drop-list__item--parameter .drop-list__item__name__remove-item {
  background-color: #F1F2F3;
}
.cdk-drag-preview.drop-list__item.drop-list__item--parameter .mat-mdc-checkbox .mdc-checkbox__background {
  border-color: #ccc !important;
}
.cdk-drag-preview.drop-list__item.drop-list__item--parameter .mat-mdc-checkbox .mdc-checkbox__background .mdc-checkbox__background .mdc-checkbox__checkmark {
  stroke: #ccc !important;
}

.cdk-drag-placeholder {
  opacity: 1;
  border: 2px dashed #5f8ab4 !important;
  background: unset !important;
}
.cdk-drag-placeholder > div {
  display: none !important;
}

.cdk-drag-animating {
  transition: transform 250ms cubic-bezier(0, 0, 0.2, 1);
}

.drop-list.cdk-drop-list-dragging .drop-list__item:not(.cdk-drag-placeholder) {
  transition: transform 250ms cubic-bezier(0, 0, 0.2, 1);
}

.border-primary {
  background: none;
  border: 2px solid #5f8ab4 !important;
}

.border-right-dark {
  background: none;
  border-right: 1px solid #4e5360 !important;
}

.background-none {
  background: none;
}

.background-primary {
  background-color: #5f8ab4;
}

.background-white {
  background-color: #FCFCFC;
}

.background-red {
  background-color: #ff7979;
}

.background-green {
  background-color: #33D9B2;
}

.background-lightred {
  background-color: #ff808b;
}

.primary-text-color {
  color: #5f8ab4;
}
.primary-text-color.hoverable {
  cursor: pointer;
}
.primary-text-color.hoverable:hover {
  text-decoration: underline;
}

.white-text {
  color: #FCFCFC;
}

.primary-text {
  color: #222222;
}

.secondary-text {
  color: #6c747c;
}

.red-text {
  color: #ff7979;
}

.green-text {
  color: #33D9B2;
}

.status-filled {
  background-color: #33d9b2;
}

.status-partially-filled {
  background-color: #ffb142;
}

.status-untouched {
  background-color: #ff7979;
}

.status-pending {
  background-color: #5f8ab4;
}

.status-approved {
  background-color: #33D9B2;
}

.status-canceled {
  background-color: black;
}

.status-rejected {
  background-color: #ff7979;
}

:root {
  --heading-h1-size: 3rem;
  --heading-h2-size: 2.5rem;
  --heading-h3-size: 2rem;
  --heading-h4-size: 1.5rem;
  --heading-h5-size: 1.25rem;
  --heading-h6-size: 1.125rem;
  --paragraph-small-font-size: .625rem;
  --paragraph-regular-font-size: .75rem;
  --paragraph-large-font-size: .875rem;
  --paragraph-extra-large-font-size: 1rem;
  --main-dark-color: #28435D;
  --main-base-color: #5F8AB4;
  --main-light-color: #75A4D1;
  --neutral-dark-color: #414447;
  --neutral-base-color: #868686;
  --neutral-light-color: #CACACA;
  --status-color-error: #F45050;
  --status-color-error-light: #f9a6ad;
  --status-color-warning: #FB8D00;
  --status-color-warning-light: #f9c17a;
  --status-color-success: #33D9B2;
  --status-color-success-light: #EBFBF7;
  --backgorund-color-red: #F45050;
  --backgorund-color-light-blue: #F4FAFF;
  --background-color-light-green: #33D9B2;
  --background-color-gray: #F1F2F3;
  --background-color-light-gray: #FAFAFA;
  --background-color-white: #FCFCFC;
  --study-state-inPreparation-background-color: #EBEBEC;
  --study-state-inPreparation-text-color: #48494C;
  --study-state-active-background-color: #B3FF96;
  --study-state-active-text-color: #0A5500;
  --study-state-paused-background-color: #FFEC6D;
  --study-state-paused-text-color: #5D4500;
  --study-state-aborted-background-color: #B3FF96;
  --study-state-aborted-text-color: #970000;
  --study-state-completed-background-color: #83FFF7;
  --study-state-completed-text-color: #00524D;
  --bs-body-font-size: 14px;
}

.row {
  --bs-gutter-x: 1rem;
}

.width-50 {
  max-width: 50%;
  width: 50%;
}

.width-100 {
  max-width: 100%;
  width: 100%;
}

.height-100 {
  height: 100%;
}

.text-center {
  text-align: center;
}

.text-right {
  text-align: right !important;
}

.text-left {
  text-align: right !important;
}

.border-none {
  border: none !important;
}

.cursor-pointer {
  cursor: pointer;
}

.cursor-not-allowed {
  cursor: not-allowed !important;
}

.margin-0 {
  margin: 0;
}

.padding-0 {
  padding: 0;
}

.font-style-italic {
  font-style: italic;
}

.position-relative {
  position: relative !important;
}

.flex-1 {
  flex: 1;
}

.justify-content-center {
  justify-content: center;
}

.justify-content-space-between {
  justify-content: space-between;
}

.justify-content-flex-end {
  justify-content: flex-end;
}

.flex-direction-column {
  flex-direction: column;
}

.flex-direction-row-reverse {
  flex-direction: row-reverse;
}

.flex-wrap-wrap {
  flex-wrap: wrap;
}

.align-items-center {
  align-items: center;
}

.display-block {
  display: block !important;
}

.display-grid {
  display: grid;
}

.display-inline-block {
  display: inline-block;
}

.display-none {
  display: none !important;
}

.text-transform-uppercase {
  text-transform: uppercase;
}

.margin-auto {
  margin-left: auto;
  margin-right: auto;
}

.big__title {
  font-size: 36px !important;
  font-weight: bold;
}

.section__title {
  font-size: 20px !important;
  font-weight: bold;
}

.section__regular__title {
  font-size: 16px !important;
}

.section__bold__title {
  font-size: 16px;
  font-weight: 500;
}

input {
  border-radius: 6px;
}

.display-none {
  display: none !important;
}

.hide {
  visibility: hidden;
  opacity: 0;
}

.m-auto {
  margin: 0 auto !important;
}

.mt-10 {
  margin-top: 10px;
}

.mr-10 {
  margin-right: 10px;
}

.mb-10 {
  margin-bottom: 10px;
}

.ml-10 {
  margin-left: 10px !important;
}

.ml-auto {
  margin-left: auto;
}

.mtb-10 {
  margin-top: 10px;
  margin-bottom: 10px;
}

.mt-15 {
  margin-top: 15px;
}

.mr-15 {
  margin-right: 15px !important;
}

.mb-15 {
  margin-bottom: 15px;
}

.ml-15 {
  margin-left: 15px;
}

.mtb-15 {
  margin-top: 15px !important;
  margin-bottom: 15px !important;
}

.mt-15 {
  margin-top: 15px;
}

.mr-15 {
  margin-right: 15px !important;
}

.mb-15 {
  margin-bottom: 15px;
}

.ml-15 {
  margin-left: 15px !important;
}

.mtb-15 {
  margin-top: 15px;
  margin-bottom: 15px;
}

.mt-20 {
  margin-top: 20px !important;
}

.mr-20 {
  margin-right: 20px !important;
}

.mb-20 {
  margin-bottom: 20px !important;
}

.ml-20 {
  margin-left: 20px !important;
}

.mtb-20 {
  margin-top: 20px;
  margin-bottom: 20px;
}

.mt-30 {
  margin-top: 30px !important;
}

.mr-30 {
  margin-right: 30px !important;
}

.mb-30 {
  margin-bottom: 30px !important;
}

.ml-30 {
  margin-left: 30px !important;
}

.mtb-30 {
  margin-top: 30px;
  margin-bottom: 30px;
}

.mt-40 {
  margin-top: 40px;
}

.mr-40 {
  margin-right: 40px;
}

.mb-40 {
  margin-bottom: 40px;
}

.ml-40 {
  margin-left: 40px;
}

.mtb-40 {
  margin-top: 40px;
  margin-bottom: 40px;
}

.mt-50 {
  margin-top: 50px !important;
}

.mr-50 {
  margin-right: 50px;
}

.mb-50 {
  margin-bottom: 50px;
}

.ml-50 {
  margin-left: 50px;
}

.mtb-50 {
  margin-top: 50px;
  margin-bottom: 50px;
}

.mt-60 {
  margin-top: 60px !important;
}

.mr-60 {
  margin-right: 60px;
}

.mb-60 {
  margin-bottom: 60px;
}

.ml-60 {
  margin-left: 60px;
}

.pr-0 {
  padding-right: 0;
}

.pt-10 {
  padding-top: 10px;
}

.pr-10 {
  padding-right: 10px;
}

.pb-10 {
  padding-bottom: 10px;
}

.pl-10 {
  padding-left: 10px;
}

.plr-10 {
  padding-left: 10px !important;
  padding-right: 10px !important;
}

.p1b-10 {
  padding-left: 10px;
  padding-right: 10px;
}

.pl-15 {
  padding-left: 15px;
}

.p-20 {
  padding: 20px;
}

.pt-20 {
  padding-top: 20px;
}

.pr-20 {
  padding-right: 20px;
}

.pb-20 {
  padding-bottom: 20px;
}

.pl-20 {
  padding-left: 20px;
}

.plr-20 {
  padding-left: 20px !important;
  padding-right: 20px !important;
}

.p-25 {
  padding: 25px;
}

.pt-25 {
  padding-top: 25px;
}

.pr-25 {
  padding-right: 25px;
}

.pb-25 {
  padding-bottom: 25px;
}

.pl-25 {
  padding-left: 25px;
}

.plr-25 {
  padding-left: 25px !important;
  padding-right: 25px !important;
}

.p-30 {
  padding: 30px !important;
}

.pt-30 {
  padding-top: 30px;
}

.pr-30 {
  padding-right: 30px;
}

.pb-30 {
  padding-bottom: 30px;
}

.pl-30 {
  padding-left: 30px;
}

.plr-30 {
  padding-left: 30px !important;
  padding-right: 30px !important;
}

.pt-40 {
  padding-top: 40px;
}

.pr-40 {
  padding-right: 40px;
}

.pb-40 {
  padding-bottom: 40px;
}

.pl-40 {
  padding-left: 40px;
}

.plr-40 {
  padding-left: 40px !important;
  padding-right: 40px !important;
}

.pt-50 {
  padding-top: 50px;
}

.pr-50 {
  padding-right: 50px;
}

.pb-50 {
  padding-bottom: 50px;
}

.pl-50 {
  padding-left: 50px;
}

.plr-50 {
  padding-left: 50px !important;
  padding-right: 50px !important;
}

.artidis-btn-md {
  height: 44px;
}

.transparent-button {
  cursor: pointer;
  background: none !important;
  border: none !important;
}

.xs-title,
.sm-title,
.md-title,
.xl-title {
  font-weight: 700;
  letter-spacing: 1px;
}

.sm-title {
  font-size: 1rem;
}

.md-title {
  font-size: 1.25rem;
}

.xl-title {
  font-size: 1.5rem;
}

.p-sm {
  font-size: 0.75rem;
}

.font-weight-bold {
  font-weight: bold;
}

.font-weight-normal {
  font-weight: normal;
}

.rotate-90 {
  -webkit-transform: rotate(90deg);
  transform: rotate(90deg);
}

.rotate-180 {
  -webkit-transform: rotate(180deg);
  transform: rotate(180deg);
}

.flip-360 {
  -webkit-transform: scaleX(-1);
  transform: scaleX(-1);
}

.page-head-wrapper {
  min-height: 64px;
}

.page-head {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 50px;
  padding-top: 0.5rem;
  z-index: 10001;
}
@media (max-width: 767px) {
  .page-head {
    margin-bottom: 20px;
    flex-direction: column;
    justify-content: unset;
    align-items: unset;
  }
}
.page-head__left__case-identifier {
  display: none;
}
.page-head__right {
  display: flex;
  justify-content: flex-end;
  align-items: center;
}
@media (max-width: 767px) {
  .page-head__right {
    justify-content: unset;
    align-items: unset;
    flex-direction: column;
    margin-top: 20px;
  }
  .page-head__right button {
    width: 100%;
    margin: 0 0 10px 0 !important;
  }
}
.page-head.fixed {
  position: fixed;
  top: 72px;
  background-color: #FCFCFC;
  border-bottom: 2px solid #e7e7e7;
  z-index: 9999;
  justify-content: space-between;
  padding: 0.8rem 1rem;
  margin-left: -13px;
}
@media (max-width: 1199px) {
  .page-head.fixed {
    width: 100% !important;
    top: 110px !important;
    padding-left: 6rem;
    left: 0;
    margin: 0;
  }
}
@media (max-width: 767px) {
  .page-head.fixed {
    padding: 2rem;
  }
}
.page-head.fixed .page-head__left__case-identifier {
  display: flex;
  flex-direction: column;
}
.page-head.fixed .page-head__left div:not(.page-head__left__case-identifier) {
  display: none;
}

.qr-code {
  display: flex;
  justify-content: center;
}
.qr-code canvas {
  width: 300px !important;
  height: 300px !important;
}

.drag-drop-area {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 200px;
  max-height: 100%;
  max-width: 100%;
  border: 4px dashed #e3e3e3;
}
.drag-drop-area__content {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
}
.drag-drop-area__content button {
  margin-bottom: 10px;
}
.drag-drop-area p {
  font-style: italic;
  margin: 0 0 5px 0;
}

.custom-modal__head {
  margin-bottom: 20px;
}
.custom-modal__head__content {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.custom-modal__head__content__button-close i {
  font-size: 20px;
}
.custom-modal .mat-divider {
  margin: 0 -24px;
}

.custom-tabs {
  margin-bottom: 1.2rem;
}
.custom-tabs__list {
  list-style: none;
  margin: 0;
  padding: 0;
  display: flex;
}
.custom-tabs__list__item {
  display: flex;
  align-items: center;
  justify-content: center;
}
.custom-tabs__list__item.active {
  color: #5f8ab4;
  border-bottom: 3px solid #5f8ab4;
}

.input-password .mat-focused.mat-form-field-appearance-outline.mat-accent .mat-form-field-outline,
.input-password .mat-focused.mat-form-field-appearance-outline.mat-accent .mat-form-field-label {
  color: #FFB142;
}
.input-password .mat-focused.mat-form-field-appearance-outline.mat-primary .mat-form-field-outline,
.input-password .mat-focused.mat-form-field-appearance-outline.mat-primary .mat-form-field-label {
  color: #33D9B2;
}
.input-password .password-strength__password-length {
  display: flex;
  justify-content: flex-end;
  font-size: 0.75rem;
  margin-bottom: 0.5rem;
}
.input-password .password-strength .mat-mdc-progress-bar .mdc-linear-progress__buffer-bar {
  border-color: #F1F2F3 !important;
}
.input-password .password-strength .mat-mdc-progress-bar.mat-accent .mdc-linear-progress__bar-inner {
  border-color: #FFB142;
}
.input-password .password-strength .mat-mdc-progress-bar.mat-primary .mdc-linear-progress__bar-inner {
  border-color: #33D9B2;
}
.input-password .password-strength__info {
  background-color: #F1F2F3;
  border-bottom-left-radius: 4px;
  border-bottom-right-radius: 4px;
}
.input-password .password-strength__info .mat-mdc-card {
  background: none;
  padding: 0 1rem 1rem 1rem;
  justify-content: unset;
}
.input-password .password-strength__info .mat-icon.mat-primary {
  color: #33D9B2;
}
.input-password .password-strength__info__toggle {
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 2.75rem;
  border-bottom-left-radius: 4px;
  border-bottom-right-radius: 4px;
  cursor: pointer;
  padding: 0 1rem;
  background: #5f8ab4;
  color: #FCFCFC;
}
.input-password .password-strength__info__toggle--toggled {
  color: #000000;
  border-radius: 0;
  background: #F1F2F3;
}

.ql-toolbar.ql-snow + .ql-container.ql-snow {
  min-height: 150px;
}

.mat-mdc-form-field-has-icon-suffix .mat-mdc-text-field-wrapper {
  padding-right: 0.75rem !important;
}

.mat-mdc-progress-spinner circle {
  stroke: #5f8ab4;
}

.mat-mdc-form-field-infix {
  --mat-form-field-container-height: 50px;
  --mat-form-field-filled-with-label-container-padding-top: 22px;
  --mat-form-field-filled-with-label-container-padding-bottom: 4px ;
}

.dicom-viewer-modal .mat-mdc-dialog-container {
  padding: 0 !important;
  background-color: #1f2531 !important;
  overflow: hidden;
}

.dicom-viewer__toolbar__dropdown {
  background-color: #1f2531 !important;
  padding: 5px;
}

.border-primary {
  background: none;
  border: 2px solid #5f8ab4 !important;
}

.border-right-dark {
  background: none;
  border-right: 1px solid #4e5360 !important;
}

.background-none {
  background: none;
}

.background-primary {
  background-color: #5f8ab4;
}

.background-white {
  background-color: #FCFCFC;
}

.background-red {
  background-color: #ff7979;
}

.background-green {
  background-color: #33D9B2;
}

.background-lightred {
  background-color: #ff808b;
}

.primary-text-color {
  color: #5f8ab4;
}
.primary-text-color.hoverable {
  cursor: pointer;
}
.primary-text-color.hoverable:hover {
  text-decoration: underline;
}

.white-text {
  color: #FCFCFC;
}

.primary-text {
  color: #222222;
}

.secondary-text {
  color: #6c747c;
}

.red-text {
  color: #ff7979;
}

.green-text {
  color: #33D9B2;
}

.status-filled {
  background-color: #33d9b2;
}

.status-partially-filled {
  background-color: #ffb142;
}

.status-untouched {
  background-color: #ff7979;
}

.status-pending {
  background-color: #5f8ab4;
}

.status-approved {
  background-color: #33D9B2;
}

.status-canceled {
  background-color: black;
}

.status-rejected {
  background-color: #ff7979;
}

.cal-month-view .cal-day-cell .cal-day-number {
  width: 25px;
  height: 25px;
  color: #6c747c;
  margin: 15px 0 10px 15px;
  float: left;
  opacity: 1;
  font-size: 14px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.cal-month-view .cal-day-cell.cal-today {
  background: none;
}
.cal-month-view .cal-day-cell.cal-today .cal-day-number {
  color: #FCFCFC;
  background-color: #5f8ab4;
  border-radius: 100%;
  font-size: 14px;
}

.border-primary {
  background: none;
  border: 2px solid #5f8ab4 !important;
}

.border-right-dark {
  background: none;
  border-right: 1px solid #4e5360 !important;
}

.background-none {
  background: none;
}

.background-primary {
  background-color: #5f8ab4;
}

.background-white {
  background-color: #FCFCFC;
}

.background-red {
  background-color: #ff7979;
}

.background-green {
  background-color: #33D9B2;
}

.background-lightred {
  background-color: #ff808b;
}

.primary-text-color {
  color: #5f8ab4;
}
.primary-text-color.hoverable {
  cursor: pointer;
}
.primary-text-color.hoverable:hover {
  text-decoration: underline;
}

.white-text {
  color: #FCFCFC;
}

.primary-text {
  color: #222222;
}

.secondary-text {
  color: #6c747c;
}

.red-text {
  color: #ff7979;
}

.green-text {
  color: #33D9B2;
}

.status-filled {
  background-color: #33d9b2;
}

.status-partially-filled {
  background-color: #ffb142;
}

.status-untouched {
  background-color: #ff7979;
}

.status-pending {
  background-color: #5f8ab4;
}

.status-approved {
  background-color: #33D9B2;
}

.status-canceled {
  background-color: black;
}

.status-rejected {
  background-color: #ff7979;
}

.mat-mdc-button {
  padding-left: 1rem;
  padding-right: 1rem;
}
.mat-mdc-button.close i {
  font-size: 1.875rem;
}
.mat-mdc-button.button-gray, .artidis-modal .mat-mdc-dialog-actions .mat-mdc-button.button-close {
  background-color: #f1f2f3;
}
.mat-mdc-button.button-green {
  color: #FCFCFC !important;
  background-color: #33D9B2 !important;
}
.mat-mdc-button.button-danger:not(.mat-mdc-outlined-button) {
  color: #FCFCFC !important;
  background-color: #ff7979;
}
.mat-mdc-button.button-primary-transparent {
  border: 2px solid #5f8ab4;
  background: none;
  color: #5f8ab4 !important;
  -webkit-transition: all 500ms;
  -moz-transition: all 500ms;
  -o-transition: all 500ms;
  transition: all 500ms;
}
.mat-mdc-button.button-primary-transparent:hover {
  color: #FCFCFC !important;
  border: 2px solid transparent;
  background-color: #5f8ab4;
}
.mat-mdc-button.button-gray-transparent {
  border: 2px solid #f1f2f3;
  background: none;
  color: #5f8ab4 !important;
  -webkit-transition: all 500ms;
  -moz-transition: all 500ms;
  -o-transition: all 500ms;
  transition: all 500ms;
}
.mat-mdc-button.button-gray-transparent:hover {
  border: 2px solid transparent;
  background-color: rgba(95, 138, 180, 0.15);
}
.mat-mdc-button.button-light-transparent {
  border: 2px solid #f1f2f3;
  background: #fff;
  color: #141516 !important;
  font-size: 12px;
  -webkit-box-shadow: 0 0 1px 0.5px rgb(217, 217, 219);
  -moz-box-shadow: 0 0 1px 0.5px rgb(217, 217, 219);
  box-shadow: 0 0 1px 0.5px rgb(217, 217, 219);
  padding: 8px !important;
}
.mat-mdc-button.button-danger-transparent {
  border: 2px solid #F45050;
  background: #FCFCFC;
  color: #F45050 !important;
  -webkit-transition: all 500ms;
  -moz-transition: all 500ms;
  -o-transition: all 500ms;
  transition: all 500ms;
}
.mat-mdc-button.button-danger-transparent:hover {
  color: #FCFCFC !important;
  border: 2px solid transparent;
  background-color: #F45050;
}

.mat-mdc-button.mat-mdc-button-base,
.mat-mdc-raised-button.mat-mdc-button-base,
.mat-mdc-unelevated-button.mat-mdc-button-base,
.mat-mdc-outlined-button.mat-mdc-button-base {
  height: 2.75rem;
}
.mat-mdc-button.mat-mdc-button-base:hover, .mat-mdc-button.mat-mdc-button-base:active,
.mat-mdc-raised-button.mat-mdc-button-base:hover,
.mat-mdc-raised-button.mat-mdc-button-base:active,
.mat-mdc-unelevated-button.mat-mdc-button-base:hover,
.mat-mdc-unelevated-button.mat-mdc-button-base:active,
.mat-mdc-outlined-button.mat-mdc-button-base:hover,
.mat-mdc-outlined-button.mat-mdc-button-base:active {
  box-shadow: none !important;
}

body:not(.at) button,
.mat-mdc-raised-button,
.mat-mdc-button {
  height: 2.75rem;
  font-weight: 700;
  letter-spacing: 0;
  outline: none;
  box-shadow: none;
  border-radius: 6px;
  border: none;
}
body:not(.at) button.has-spinner,
.mat-mdc-raised-button.has-spinner,
.mat-mdc-button.has-spinner {
  display: flex;
  align-items: center;
  justify-content: center;
}
body:not(.at) button .mat-mdc-progress-spinner:not(.mat-primary) circle,
.mat-mdc-raised-button .mat-mdc-progress-spinner:not(.mat-primary) circle,
.mat-mdc-button .mat-mdc-progress-spinner:not(.mat-primary) circle {
  stroke: #fff;
}
body:not(.at) button.only-icon,
.mat-mdc-raised-button.only-icon,
.mat-mdc-button.only-icon {
  width: 2.75rem;
  height: 2.75rem;
  padding: 0;
  border-radius: 4px;
}
body:not(.at) button.only-icon-sm,
.mat-mdc-raised-button.only-icon-sm,
.mat-mdc-button.only-icon-sm {
  width: 2rem;
  height: 2rem;
  padding: 0;
  border-radius: 4px;
}
body:not(.at) button:disabled,
.mat-mdc-raised-button:disabled,
.mat-mdc-button:disabled {
  cursor: not-allowed;
  color: #222 !important;
  opacity: 0.4;
}
body:not(.at) button:disabled:not(.button-primary-transparent),
.mat-mdc-raised-button:disabled:not(.button-primary-transparent),
.mat-mdc-button:disabled:not(.button-primary-transparent) {
  cursor: not-allowed;
  color: #222 !important;
  opacity: 0.4;
}
body:not(.at) button:disabled.button-primary-transparent,
.mat-mdc-raised-button:disabled.button-primary-transparent,
.mat-mdc-button:disabled.button-primary-transparent {
  color: rgba(95, 138, 180, 0.5) !important;
  border-color: rgba(95, 138, 180, 0.5) !important;
  background: transparent !important;
}
body:not(.at) button:disabled.mat-primary,
.mat-mdc-raised-button:disabled.mat-primary,
.mat-mdc-button:disabled.mat-primary {
  color: #FCFCFC !important;
  background-color: rgba(95, 138, 180, 0.2) !important;
  opacity: initial;
}

.artidis-modal__header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-bottom: 1px solid rgba(0, 0, 0, 0.12);
  padding: 24px;
}
.artidis-modal__header__title {
  font-size: 1.5rem;
  font-weight: 700;
}
.artidis-modal__header__subtitle {
  color: #141516;
  font-size: 1rem;
}
.artidis-modal__header > .artidis-modal-subtitle, .artidis-modal__header p {
  margin: 0;
  color: #141516;
}
.artidis-modal .mat-divider {
  margin: 0 -24px;
}
.artidis-modal .mat-mdc-dialog-content {
  padding-top: 30px;
  padding-bottom: 30px;
  max-height: calc(100vh - 260px);
}
.artidis-modal .mat-mdc-dialog-content > .mat-mdc-dialog-actions {
  margin-top: 2rem !important;
}
.artidis-modal .mat-mdc-dialog-actions {
  padding: 9px 24px;
  border-top: 1px solid rgba(0, 0, 0, 0.12);
}
.artidis-modal .mat-mdc-dialog-actions button {
  min-width: 8.81rem !important;
}
.artidis-modal .mat-mdc-dialog-actions .button-close {
  margin-right: 0.5rem;
}
@media (max-width: 992px) {
  .artidis-modal .mat-mdc-dialog-actions .button-close {
    margin: 0;
  }
}

.mat-mdc-dialog-container {
  position: relative;
}
.mat-mdc-dialog-container .mdc-dialog__surface {
  overflow-x: hidden;
}

.case-table-configuration-modal .mat-mdc-dialog-container .mdc-dialog__surface {
  border-radius: 10px;
}