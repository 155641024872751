.mapSpanClass {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  margin-top: -10px;
  color: #fff;
  font-size: 100%;
  font-weight: 700;
}

.mapDivClass {
  margin-left: -25px;
}

.mapDivClass.map-orange .mapSpanClass {
  margin-top: -5px;
}
